<template>
  <div>
    <Auth />

    <Header
      title="Új időpont lefoglalása"
      muted=""
      back-route-name="ServiceProviderAppointments"
      back-route-param="id"
      custom-class="bg-active text-light"
    />

    <div class="section mt-2">
      <form id="form1" action="#" enctype="multipart/form-data" method="post">
        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1">Munkanap</label>
            <div
              @click="showWorkdayModal = true"
              type="text"
              class="form-control clickable pt-1"
            >
              <span v-if="selectedDay.date"> {{ selectedDay.date }} - </span>
              {{ selectedDay.name }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1">Szolgáltatás</label>
            <div
              @click="showServicesModal = true"
              type="text"
              class="form-control clickable pt-1"
            >
              {{ selectedService.title }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1">Ügyfél</label>
            <div
              @click="showContactsModal = true"
              type="text"
              class="form-control clickable pt-1"
            >
              {{ selectedContact.name }}
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Select opcions first warning message -->
    <div
      v-if="
        selectedDay.date == null ||
        selectedService.id == null ||
        selectedContact.id == null
      "
      class="section full"
    >
      <ErrorPanel
        class-label="alert alert-primary mainGreen"
        title="Hoppá!"
        message="Először válassz az opciók közül!"
        message2="Utána válassz időpontot!"
      />
    </div>

    <!-- Appointments list -->
    <ul
      v-else-if="appointments && appointments.length > 0"
      class="listview link-listview"
    >
      <li v-for="(appointment, index) in appointments" :key="index">
        <a href="javascript:void(0)" @click="showConfirmation(appointment)">
          {{ appointment.time_start }} - {{ appointment.time_stop }}
          <span
            v-if="appointment.is_available === 1"
            class="badge badge-success"
            >ELÉRHETŐ</span
          >
          <span
            v-else-if="appointment.is_available === 2"
            class="badge badge-warning"
            >JÓVÁHAGYÁSRA VÁR</span
          >
          <span v-else class="badge badge-danger">FOGLALT</span>
        </a>
      </li>
    </ul>

    <!--Unavailable appointmets case-->
    <div v-else class="section full">
      <ErrorPanel
        class-label="alert alert-primary mainGreen"
        title="Hoppá!"
        message="Nincsenek elérhető időpontok!"
        message2="Kérlek nézz meg egy másik napot!"
      />
    </div>

    <!-- Modal to show services -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="showServicesModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz szolgáltatást</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li v-for="(service, index) in services" :key="index">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list"
                    data-dismiss="modal"
                    @click="selectService(service)"
                  >
                    <span>{{ service.title }}</span>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="showServicesModal = false"
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal to choose workday -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="showWorkdayModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz munkanapot</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li v-for="(day, index) in availableDays" :key="index">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list"
                    data-dismiss="modal"
                    @click="selectDay(day)"
                  >
                    {{ day.date }} - {{ day.name }}
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="showWorkdayModal = false"
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal to show contacts -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="showContactsModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz ügyfelet</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li v-for="(contact, index) in contacts" :key="index">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list"
                    data-dismiss="modal"
                    @click="selectContact(contact)"
                  >
                    <span>
                      {{ contact.name }}
                    </span>
                    <br />
                    <small class="text-muted">
                      {{ contact.phone }}
                    </small>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="openNewContact()"
                    class="btn btn-list text-success"
                    data-dismiss="modal"
                  >
                    <span>Új ügyfél</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="showContactsModal = false"
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal to new contact form -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="showNewContactModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">Új ügyfél</h2>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li>
                  <div class="form-group boxed px-1">
                    <div class="input-wrapper">
                      <input
                        type="text"
                        class="form-control"
                        id="newContactName"
                        name="newContactName"
                        placeholder="Név"
                        v-model="newContact.name"
                        :class="{ 'is-invalid': errors.name }"
                      />
                      <div class="invalid-feedback" v-if="errors.name">
                        {{ errors.name[0] }}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-group boxed px-1">
                    <div class="input-wrapper">
                      <input
                        type="text"
                        class="form-control"
                        id="newContactPhone"
                        name="newContactPhone"
                        placeholder="Telefonszám"
                        v-model="newContact.phone"
                        :class="{ 'is-invalid': errors.phone }"
                      />
                      <div class="invalid-feedback" v-if="errors.phone">
                        {{ errors.phone[0] }}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-group boxed px-1">
                    <div class="input-wrapper">
                      <input
                        type="text"
                        class="form-control"
                        id="newContactEmail"
                        name="newContactEmail"
                        placeholder="Email cím"
                        v-model="newContact.email"
                        :class="{ 'is-invalid': errors.email }"
                      />
                      <div class="invalid-feedback" v-if="errors.email">
                        {{ errors.email[0] }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="action-divider"></li>
                <li class="text-center">
                  <a
                    href="javascript:void(0)"
                    @click="saveNewContact()"
                    class="btn btn-list text-success"
                    data-dismiss="modal"
                  >
                    <span>Mentés</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="
                      showNewContactModal = false;
                      showContactsModal = true;
                    "
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!--Modal to show an appointment-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="showConfirmationModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton2"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Időpont mentése</h5>
            </div>

            <div class="modal-body">
              <div class="mb-2">
                <h5>Kliens adatok:</h5>
                <strong> {{ selectedContact.name }} </strong> <br />
                <strong> {{ selectedContact.phone }} </strong> <br />
                <strong> {{ selectedContact.email }} </strong> <br />
              </div>

              <div class="">
                <h5>Időpont:</h5>
                <small>Kezd:</small>
                <strong> {{ selectedAppointment.time_start }} </strong> <br />
                <small>Befejez:</small>
                <strong> {{ selectedAppointment.time_stop }} </strong>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                  href="javascript:void(0)"
                  @click="saveAppointment"
                  class="btn btn-text-success btn-block"
                  >MENTÉS</a
                >
                <a
                  href="javascript:void(0)"
                  @click="showConfirmationModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                  >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <div
      v-if="
        showServicesModal ||
        showContactsModal ||
        showNewContactModal ||
        showWorkdayModal ||
        showConfirmationModal
      "
      class="modal-backdrop fade show"
    ></div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import ErrorPanel from "@/partials/ErrorPanel";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderAppointmentNew",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
    ErrorPanel,
  },
  data: () => ({
    errors: {},
    appointments: {},
    contacts: {},
    appointment: {
      service_id: "",
      time_start: "",
      time_stop: "",
    },
    user: {},
    services: {},
    selectedService: {
      id: null,
      title: "Kérlek válassz",
    },
    selectedContact: {
      id: null,
      name: "Kérlek válassz",
      phone: "",
      email: "",
    },
    selectedAppointment: null,
    calendarChoosedDate: null,
    showContactsModal: false,
    showServicesModal: false,
    showConfirmationModal: false,
    showNewContactModal: false,
    showWorkdayModal: false,
    newContact: {
      name: "",
      phone: "",
      email: "",
    },
    availableDays: {},
    selectedDay: {
      date: null,
      name: "Kérlek válassz",
    },
  }),
  methods: {
    getServices() {
      axios
        .post(this.$store.getters.getApiUrl + "service/sp/show", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.services = response.data.services;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getWorkdays() {
      axios
        .post(this.$store.getters.getApiUrl + "workday/availableWorkDays", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.availableDays = response.data.availableDays;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getContacts() {
      axios
        .post(this.$store.getters.getApiUrl + "contact/list", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getAvailabelServices() {
      axios
        .post(this.$store.getters.getApiUrl + "service/show/appointments", {
          token: this.$store.getters.getToken,
          id: this.selectedService.id,
          day: this.selectedDay.date,
        })
        .then((response) => {
          if (response.status === 200) {
            this.appointments = response.data.appointments;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    selectService(service) {
      //Set service id
      this.selectedService.id = service.id;
      this.selectedService.title = service.title;

      //Get available appointments in workday (axios)
      this.getAvailabelServices();

      //Close modal
      this.showServicesModal = false;
    },
    selectDay(day) {
      //Save a selected date
      this.selectedDay = day;
      //Close the modal
      this.showWorkdayModal = false;
    },
    selectContact(contact) {
      //Set contact to selected object
      this.selectedContact = contact;
      //Close modal
      this.showContactsModal = false;
    },
    showConfirmation(appointment) {
      //Check client selection
      if (this.selectedContact.id != null) {
        //Save choosed appointment ID
        this.selectedAppointment = appointment;
        //Show confirmation modal
        this.showConfirmationModal = true;
      } else {
        this.$toasted.show("Ügyfelet kötelező választani!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: false,
          type: "error",
          // icon: "checkmark-outline"
        });
      }
    },
    saveAppointment() {
      this.errors = {};

      //Error message, empty service
      // if( !this.appointment.service_id )
      // {
      //   this.$toasted.show("Szolgáltatást kötelező választani!", {
      //     position: "bottom-center",
      //     duration: 3500,
      //     fullWidth: true,
      //     fitToScreen: false,
      //     type: "error"
      //     // icon: "checkmark-outline"
      //   });
      // }

      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/store/calendar", {
          token: this.$store.getters.getToken,
          service_id: this.selectedService.id,
          contact_id: this.selectedContact.id,
          choosed_date: this.selectedDay.date,
          time_start: this.selectedAppointment.time_start,
          time_stop: this.selectedAppointment.time_stop,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({
              name: "ServiceProviderAppointments",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    openNewContact() {
      this.showContactsModal = false;
      setTimeout(() => {
        this.newContact.name = "";
        this.newContact.email = "";
        this.newContact.phone = "";
        this.showNewContactModal = true;
      }, 200);
    },
    saveNewContact() {
      axios
        .post(this.$store.getters.getApiUrl + "contact/storeFromSpCalendar", {
          token: this.$store.getters.getToken,
          name: this.newContact.name,
          phone: this.newContact.phone,
          email: this.newContact.email,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
            this.selectedContact = response.data.selectedContact;
            this.showNewContactModal = false;

            //Toaster from success confirmation
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
    this.getServices();
    this.getContacts();
    this.getWorkdays();
  },
};
</script>
