<template>
  <div class="headerContainerRegistration">
    <div class="appHeader bg-active text-light no-border">
      <div class="left">
        <a
          v-if="step === 1"
          @click="$router.push({ name: 'Registration' })"
          class="headerButton"
          href="#"
        >
          <div class="icon-custom icon-chevron-back-white"></div>
        </a>

        <a
          v-if="step === 2"
          @click="$router.push({ name: 'CustomerStep1' })"
          class="headerButton"
        >
          <div class="icon-custom icon-chevron-back-white"></div>
        </a>
      </div>
      <div class="pageTitle">Regisztráció - Ügyfélként</div>
      <div class="right"></div>
    </div>

    <div class="extraHeader p-0">
      <div class="form-wizard-section">
        <a
          href="javascript:void(0);"
          class="button-item"
          :class="{ active: step === 1 }"
        >
          <strong>1</strong>
          <p>Alap adatok</p>
        </a>
        <a
          href="javascript:void(0);"
          class="button-item"
          :class="{ active: step === 2 }"
        >
          <strong>2</strong>
          <p>Profil</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "CustomerStep1",
  data: () => ({
    backStep: 0,
  }),
  components: {},
  props: {},
  computed: {
    step() {
      // this.backStep = this.$store.state.registration.step;
      return this.$store.state.registration.step;
    },
    type() {
      return this.$store.state.registration.type;
    },
  },
};
</script>
