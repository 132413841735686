<template>
  <div>
    <Auth />

    <Header
      title="Üzenetek"
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <!-- New message button -->
    <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        @click="messageModal = true"
        class="fab mainGreen"
        aria-expanded="false"
      >
        <ion-icon
          name="mail-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
      </a>
    </div>

    <div v-if="conversations && user.type != 0" class="section inset">
      <div v-for="(conversation, index) in conversations" :key="index">
        <div
          class="wide-block wideBlockMainGreen pt-2 pb-2 mt-1"
          v-if="conversation.client"
          @click="
            $router.push({
              name: 'MessagesShow',
              params: { id: conversation.id },
            })
          "
        >
          <div class="content-header">Beszélgetés vele:</div>
          <div
            class="text-white"
            v-if="conversation.client && conversation.client.name"
          >
            {{ conversation.client.name }}
          </div>
          <div
            class="text-primary text-right float-right mt-n5"
            style="font-size: 2rem"
          >
            <!--<ion-icon name="chevron-forward-circle-outline"></ion-icon>-->
            <div class="icon-custom icon-right-arrow-white"></div>
          </div>

          <div class="content-footer my-2">
            <div class="text-left d-inline-block">
              <div
                v-if="conversation.isUnread"
                class="chip chip-danger ml-05 mb-05"
              >
                <span class="chip-label">Új üzenet!</span>
              </div>
            </div>
            <div class="text-right d-inline-block float-right">
              Telefonszám: {{ conversation.client.phone }} <br />
              Email: {{ conversation.client.email }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="conversations && user.type == 0" class="section inset">
      <div
        class="wide-block wideBlockMainGreen text-white pt-2 pb-2 mt-1"
        v-for="(conversation, index) in conversations"
        :key="index"
        href="javascript:void(0)"
        @click="
          $router.push({
            name: 'MessagesShow',
            params: { id: conversation.id },
          })
        "
      >
        <div class="content-header text-white mb-05">Beszélgetés vele:</div>
        {{ conversation.service_provider.name }}

        <div
          class="d-inline-block text-white text-right float-right"
          style="font-size: 2rem; margin-top: -1.5rem"
        >
          <!-- <ion-icon name="chevron-forward-circle-outline"></ion-icon> -->
          <div class="icon-custom icon-chevron-forward-circle-outline"></div>
        </div>

        <div class="content-footer my-2">
          <div class="text-left d-inline-block">
            <div
              v-if="conversation.isUnread"
              class="chip chip-danger ml-05 mb-05"
            >
              <span class="chip-label">Új üzenet!</span>
            </div>
          </div>
          <div class="text-right d-inline-block float-right">
            Telefonszám: {{ conversation.service_provider.phone }} <br />
            Email: {{ conversation.service_provider.email }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="section full mb-2">
      <ErrorPanel
        title="Hoppá!"
        class-label="alert alert-primary mainGreen"
        message="Jelenleg egy beszélgetés sincs"
      />
    </div>

    <Nav />
    <NavFooter />

    <!--Send message to ServiceProvider Modal-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="messageModal"
        class="modal fade dialogbox show"
        id="DialogForm"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Üzenet küldés</h5>
            </div>
            <div class="modal-body text-left mb-2">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="message">Címzett</label>
                  <input
                    type="text"
                    id="message"
                    class="form-control"
                    placeholder="Kinek szeretnéd küldeni?"
                    v-model="selectedContact.name"
                    @click="
                      showContactsModal = true;
                      messageModal = false;
                    "
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <div class="modal-body text-left mb-2">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="message">Üzenet</label>
                  <input
                    type="text"
                    id="message"
                    class="form-control"
                    placeholder="Mit szeretnél üzenni?"
                    v-model="message"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="btn-inline">
                <button
                  type="button"
                  class="btn btn-text-secondary"
                  data-dismiss="modal"
                  @click="messageModal = false"
                >
                  Bezár
                </button>
                <button
                  type="button"
                  class="btn btn-text-primary"
                  data-dismiss="modal"
                  @click="sendMessage"
                >
                  Küldés
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal to show contacts -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="showContactsModal"
        class="modal fade dialogbox show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz egy kapcsolatot</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li v-for="(contact, index) in contacts" :key="index">
                  <a
                    v-if="contact.contact_user_id != null"
                    href="javascript:void(0)"
                    class="btn btn-list"
                    data-dismiss="modal"
                    @click="selectContact(contact)"
                  >
                    <span>
                      {{ contact.name }}
                    </span>
                    <br />
                    <small class="text-muted">
                      {{ contact.phone }}
                    </small>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="
                      showContactsModal = false;
                      messageModal = true;
                    "
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="showContactsModal || messageModal"
      class="modal-backdrop fade show"
    ></div>
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Messages",
  components: {
    ErrorPanel,
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    conversations: {},
    user: {},
    toUserId: null,
    contacts: {},
    selectedContact: {
      name: "",
      id: 0,
    },
    message: "",
    showContactsModal: false,
    messageModal: false,
  }),
  methods: {
    getConversations() {
      axios
        .post(this.$store.getters.getApiUrl + "conversation/show", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.conversations = response.data.conversations;
            this.contacts = response.data.contacts;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    selectContact(contact) {
      //Set contact to selected object
      this.selectedContact = contact;
      //Close contact modal
      this.showContactsModal = false;
      //Open new message modal
      this.messageModal = true;
    },
    sendMessage() {
      if (!this.message) {
        this.$toasted.show("Üzenet megadása kötelező!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: false,
          type: "error",
          // icon: "checkmark-outline"
        });
      } else {
        //Select the message to user id
        console.log("selected contact:", this.selectedContact);
        if (this.user.id == this.selectedContact.contact_user_id) {
          this.toUserId = this.selectedContact.user_id;
        } else {
          this.toUserId = this.selectedContact.contact_user_id;
        }

        axios
          .post(
            this.$store.getters.getApiUrl +
              "conversation/message/sendFromMessages",
            {
              token: this.$store.getters.getToken,
              to_user_id: this.toUserId,
              message: this.message,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              //Close modal & reset message value
              this.messageModal = false;
              this.message = "";

              //Update conversations
              this.conversations = response.data.conversations;

              //Show a toaster with success
              this.$toasted.show("Sikeres üzenetküldés!", {
                position: "bottom-center",
                duration: 2500,
                fullWidth: true,
                fitToScreen: false,
                type: "success",
                // icon: "checkmark-outline"
              });
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
  },
  computed: {},
  mounted() {
    this.getConversations();
    this.user = this.$store.getters.getUserData;
  },
};
</script>
