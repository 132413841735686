<template>
  <div>
    <Auth />

    <Header
      title="Időpontjaim"
      muted=""
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        @click="
          $router.push({
            name: 'ServiceProviderAppointmentNew',
          })
        "
        class="fab"
      >
        <ion-icon
          name="add-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
      </a>
    </div>

    <!-- <div v-if="appointments && appointments.length > 0" class="section inset">
      <div
        class="wide-block pt-2 pb-2 mt-1"
        v-for="(appointment, index) in appointments"
        :key="index"
        href="javascript:void(0)"
        @click="
          $router.push({
            name: 'ServiceProviderAppointmentEdit',
            params: { id: appointment.id, service_id: service.id }
          })
        "
      >
        <div class="content-header mb-05">
          {{ appointment.time_start }} - {{ appointment.time_stop }}
        </div>
        {{ appointment.service.title }}

        <div
          class="d-inline-block text-right float-right"
          style="font-size: 2rem; margin-top: -1.5rem;"
        >
          <ion-icon name="chevron-forward-circle-outline"></ion-icon>
        </div>

        <div class="content-footer mt-05 text-right">
          <div class="row">
            <div class="col-6 text-left">
              <span
                v-if="appointment.is_available === 1"
                class="badge badge-success"
                >ELÉRHETŐ</span
              >
              <span
                v-else-if="appointment.is_available === 2"
                class="badge badge-warning badgeSmallText"
                >JÓVÁHAGYÁSRA VÁR</span
              >
              <span
                v-else-if="appointment.is_available === 3"
                class="badge badge-success badgeSmallText"
                >MEGERŐSÍTVE</span
              >
            </div>
            <div class="col-6 text-right">
              Időtartam: {{ appointment.service.duration }} perc <br />
              Ár: {{ appointment.service.price }} HUF
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="section full">
      <ErrorPanel
        class-label="alert alert-danger mainGreen"
        title="Hoppá!"
        message="Nincsenek elérhető időpontok!"
        message2=""
      />
    </div> -->

    <div class="section full">
      <!-- Appointments list -->
      <ul
        v-if="appointments && appointments.length > 0"
        class="listview link-listview"
      >
        <li v-for="(appointment, index) in appointments" :key="index">
          <a href="javascript:void(0)" @click="selectAppointment(appointment)">
            <div>
              <small>
                <strong v-if="appointment.client && appointment.client.name">
                  {{ appointment.client.name }}
                </strong>
                <strong v-else>{{ appointment.contact.name }}</strong>
              </small>
              <br />
              <small>{{ appointment.service.title }}</small> <br />
              {{ appointment.time_start }}
            </div>
            <span
              v-if="appointment.is_available === 1"
              class="badge badge-success"
              >ELÉRHETŐ</span
            >
            <span
              v-else-if="appointment.is_available === 2"
              class="badge badge-warning"
              >JÓVÁHAGYÁSRA VÁR</span
            >
            <span
              v-else-if="appointment.is_available === 3"
              class="badge badge-success"
              >MEGERŐSÍTVE</span
            >
            <span
              v-else-if="appointment.is_available === 4"
              class="badge badge-warning"
              >TÖRLÉS JÓVÁHAGYÁSRA VÁR</span
            >
          </a>
        </li>
      </ul>

      <div v-else class="section full">
        <ErrorPanel
          class-label="alert alert-danger mainGreen"
          title="Hoppá!"
          message="Nincsenek elérhető időpontok!"
          message2=""
        />
      </div>
    </div>

    <!--Modal to show an appointment-->
    <!-- Modal to show contacts -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="actionsModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz műveletet</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list text-black-100"
                    data-dismiss="modal"
                    @click="
                      $router.push({
                        name: 'ServiceProviderAppointmentEdit',
                        params: { id: detailedAppointment.id },
                      })
                    "
                  >
                    <span>
                      <ion-icon
                        name="pencil-outline"
                        role="img"
                        class="md hydrated"
                      ></ion-icon>
                      Módosítás
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list text-black-100"
                    data-dismiss="modal"
                    @click="
                      actionsModal = false;
                      deletionConfirmationModal = true;
                    "
                  >
                    <span>
                      <ion-icon
                        name="trash-outline"
                        role="img"
                        class="md hydrated"
                      ></ion-icon>
                      Törlés
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="actionsModal = false"
                    class="btn btn-list text-black-100"
                    data-dismiss="modal"
                  >
                    <span>
                      <ion-icon
                        name="close-outline"
                        role="img"
                        class="md hydrated"
                      ></ion-icon>
                      Bezár
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Deletion confirmation modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="deletionConfirmationModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Megerősítés</h5>
            </div>
            <div class="modal-body">Biztosan törölni szeretnéd?</div>
            <div class="modal-footer">
              <div class="btn-list">
                <a
                  href="javascript:void(0)"
                  @click="deleteAppointment"
                  class="btn btn-text-danger btn-block"
                  data-dismiss="modal"
                  >TÖRLÉS</a
                >
                <a
                  href="javascript:void(0)"
                  @click="
                    deletionConfirmationModal = false;
                    actionsModal = true;
                  "
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                  >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="actionsModal || deletionConfirmationModal"
      class="modal-backdrop fade show"
    ></div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderAppointments",
  components: {
    ErrorPanel,
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    appointments: {},
    user: {},
    service: {},
    actionsModal: false,
    deletionConfirmationModal: false,
    detailedAppointment: {},
  }),
  methods: {
    getAppointments() {
      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/show", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.appointments = response.data.appointments;
            this.service = response.data.service;
            //console.log(this.appointments);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    selectAppointment(appointment) {
      //Copy appointment to detailed appointment
      this.detailedAppointment = appointment;
      //Open modal actions
      this.actionsModal = true;
    },
    deleteAppointment() {
      //Delete appointment axios
      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/delete", {
          token: this.$store.getters.getToken,
          id: this.detailedAppointment.id,
        })
        .then((response) => {
          if (response.status === 200) {
            //Reload appointments list
            this.appointments = response.data.appointments;
            //Clsoe modal
            this.deletionConfirmationModal = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
    this.getAppointments();
  },
};
</script>
