<template>
  <div>
    <Auth />

    <Header
      title="Időpont szerkesztés"
      muted=""
      back-route-name="ServiceProviderAppointments"
      back-route-param="id"
      :back-route-id="$route.params.service_id"
      custom-class="bg-active text-light"
    />

    <div class="section full mt-3">
      <div v-if="appointment.client" class="card card3 m-2">
        <div class="card-body2">
          <h5 class="card-title2">Ügyfél adatok</h5>
          <p class="card-text card-text2">
            Név:
            <strong class="ml-1">{{ appointment.client.name }}</strong> <br />
            Telefonszám:
            <strong class="ml-1">{{ appointment.client.phone }}</strong> <br />
            Email:
            <strong class="ml-1">{{ appointment.client.email }}</strong>
          </p>
        </div>
      </div>

      <!-- <div v-else-if="appointment.contact" class="row">
        <div class="col-10 offset-1 small text-center">Ügyfél</div>
        <div class="col-10 offset-1">
          <small>Név:</small>
          <strong class="ml-1">{{ appointment.contact.name }}</strong>
        </div>
        <div v-show="appointment.contact.phone" class="col-10 offset-1">
          <small>Telefonszám:</small>
          <strong class="ml-1">{{ appointment.contact.phone }}</strong>
        </div>
        <div v-show="appointment.contact.email" class="col-10 offset-1">
          <small>Email:</small>
          <strong class="ml-1">{{ appointment.contact.email }}</strong>
        </div>
      </div> -->

      <div v-else-if="appointment.contact" class="card card3 m-2">
        <div class="card-body2">
          <h5 class="card-title2">Ügyfél adatok</h5>
          <p class="card-text card-text2">
            Név:
            <strong class="ml-1">{{ appointment.contact.name }}</strong> <br />
            Telefonszám:
            <strong class="ml-1">{{ appointment.contact.phone }}</strong> <br />
            Email:
            <strong class="ml-1">{{ appointment.contact.email }}</strong>
          </p>
        </div>
      </div>

      <!-- <div class="divider my-2 bg-fixeet"></div> -->

      <!-- <div class="row text-center">
        <div class="col-10 offset-1 small text-center">
          Szolgáltatás
          <br />
          <h3>{{ service.title }}</h3>
        </div>
        <div class="col-6">
          <small>Időtartam:</small>
          <h3 class="ml-1 d-inline">{{ service.duration }}</h3>
          <small> perc</small>
        </div>
        <div class="col-6">
          <small>Ár:</small>
          <h3 class="ml-1 d-inline">{{ service.price }}</h3>
          <small> HUF</small>
        </div>
      </div> -->

      <div class="card card3 m-2">
        <div class="card-body2">
          <h5 class="card-title2">Szolgáltatás adatok</h5>
          <!-- <p class="card-text card-text2">
            Név:
            <strong class="ml-1">{{ appointment.client.name }}</strong> <br />
            Telefonszám:
            <strong class="ml-1">{{ appointment.client.phone }}</strong> <br />
            Email:
            <strong class="ml-1">{{ appointment.client.email }}</strong>
          </p> -->
          <div class="row serviceDescription">
            <div class="col-12">
              Szolgáltatás:
              <h3 class="ml-1 d-inline">{{ service.title }}</h3>
            </div>
            <div class="col-12">
              Időtartam:
              <h3 class="ml-1 d-inline">{{ service.duration }}</h3>
              <small> perc</small>
            </div>
            <div class="col-12">
              Ár:
              <h3 class="ml-1 d-inline">{{ service.price }}</h3>
              <small> HUF</small>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="divider my-2 bg-fixeet"></div> -->

      <div class="row text-center mb-2 align-items-center">
        <div class="col-12 text-center small">Időpont</div>
        <div class="col-8 offset-1">
          <div class="form-group boxed">
            <div class="input-wrapper not-empty">
              <label class="label ml-1">Dátum</label>
              <input
                type="text"
                v-model="currentDate"
                class="form-control"
                placeholder="17:00"
              />
            </div>
          </div>
        </div>
        <div class="col-2 align-items-center">
          <button
            @click="getAvailableDays()"
            type="button"
            class="btn btn-icon btn-md btn-primary mt-3"
          >
            <ion-icon
              name="pencil-outline"
              role="img"
              class="hydrated"
              aria-label="add outline"
            ></ion-icon>
          </button>
        </div>

        <div class="col-8 offset-1">
          <div class="form-group boxed">
            <div class="input-wrapper not-empty">
              <label class="label ml-1">Időpont</label>
              <input
                type="text"
                v-model="currentTime"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="col-2 align-items-center">
          <button
            @click="getAvailabelAppointments()"
            type="button"
            class="btn btn-icon btn-md btn-primary mt-3"
          >
            <ion-icon
              name="pencil-outline"
              role="img"
              class="hydrated"
              aria-label="add outline"
            ></ion-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="divider my-2 bg-fixeet"></div> -->

    <div class="row mb-3 align-items-center">
      <!-- <div class="col-12 text-center small">Állapot</div> -->
      <div class="col-8 offset-1 text-left">
        <span
          v-if="appointment.is_available === 1"
          class="badge badge-success p-2 font-weight-bold"
          >ELÉRHETŐ</span
        >
        <span
          v-else-if="appointment.is_available === 2"
          class="badge badge-warning p-2 font-weight-bold"
          >JÓVÁHAGYÁSRA VÁR</span
        >
        <span
          v-else-if="appointment.is_available === 3"
          class="badge badge-success p-2 font-weight-bold"
          >MEGERŐSÍTVE</span
        >
      </div>
      <div class="col-2 text-center align-items-center">
        <button
          @click="statesModal = true"
          type="button"
          class="btn btn-icon btn-md btn-primary"
        >
          <ion-icon
            name="pencil-outline"
            role="img"
            class="hydrated"
            aria-label="add outline"
          ></ion-icon>
        </button>
      </div>
    </div>

    <!-- Deprecated -->
    <!-- <div class="section full">
      <div class="wide-block pt-2 pb-2">
        <button
          @click="saveAppointment"
          type="button"
          class="btn btn-success btn-lg btn-block"
        >
          <ion-icon name="save-outline"></ion-icon>
          Mentés
        </button>
      </div>
    </div> -->

    <!-- Deprecated -->
    <!-- <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="confirmationModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Megerősítés</h5>
            </div>
            <div class="modal-body">Biztosan törölni szeretnéd?</div>
            <div class="modal-footer">
              <div class="btn-list">
                <a
                  href="javascript:void(0)"
                  @click="deleteAppointment"
                  class="btn btn-text-danger btn-block"
                  data-dismiss="modal"
                  >TÖRLÉS</a
                >
                <a
                  href="javascript:void(0)"
                  @click="confirmationModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                  >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition> -->

    <!-- Free apointments modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="freeAppointmentsModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz időpontot</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li
                  v-for="(appointment, index) in freeAppointments"
                  :key="index"
                >
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list"
                    data-dismiss="modal"
                    @click="selectAppointment(appointment)"
                  >
                    <span
                      >{{ appointment.time_start }} -
                      {{ appointment.time_stop }}</span
                    >
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="freeAppointmentsModal = false"
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Available days modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="datesModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz napot</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li v-for="(day, index) in availableDays" :key="index">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-list"
                    data-dismiss="modal"
                    @click="selectDate(day)"
                  >
                    <span>{{ day.date }} - {{ day.name }}</span>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="datesModal = false"
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- State modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-if="statesModal"
        class="modal fade action-sheet inset show"
        id="actionSheetInset"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz állapotot</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="changeAppointmentState(2)"
                    class="btn btn-list"
                    data-dismiss="modal"
                  >
                    <span>
                      <ion-icon
                        v-if="appointment.is_available == 2"
                        name="checkmark-outline"
                        role="img"
                        class="hydrated"
                        aria-label="add outline"
                      ></ion-icon>
                      JÓVÁHAGYÁSRA VÁR
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="changeAppointmentState(3)"
                    class="btn btn-list"
                    data-dismiss="modal"
                  >
                    <span>
                      <ion-icon
                        v-if="appointment.is_available == 3"
                        name="checkmark-outline"
                        role="img"
                        class="hydrated"
                        aria-label="add outline"
                      ></ion-icon>
                      MEGERŐSÍTVE
                    </span>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="statesModal = false"
                    class="btn btn-list text-danger"
                    data-dismiss="modal"
                  >
                    <span>Bezár</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="freeAppointmentsModal | statesModal | datesModal"
      class="modal-backdrop fade show"
    ></div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MyServiceEdit",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    appointment: {},
    confirmationModal: false,
    freeAppointmentsModal: false,
    datesModal: false,
    statesModal: false,
    user: {},
    service: {},
    freeAppointments: {},
    availableDays: {},
    currentDate: "",
    currentTime: "",
  }),
  watch: {
    timeStart() {
      this.modifyTimeStop();
    },
  },
  methods: {
    getAppointment() {
      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/edit", {
          token: this.$store.getters.getToken,
          id: this.$route.params.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.service = response.data.service;
            this.appointment = response.data.appointment;
            this.currentDate = response.data.currentDate;
            this.currentTime =
              response.data.appointment.time_start +
              " - " +
              response.data.appointment.time_stop;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getAvailableDays() {
      axios
        .post(this.$store.getters.getApiUrl + "workday/availableWorkDays", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Available days - resp", response.data);
            this.availableDays = response.data.availableDays;
            this.currentDate = response.data.currentDate;

            this.datesModal = true;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getAvailabelAppointments() {
      axios
        .post(this.$store.getters.getApiUrl + "service/show/appointments", {
          token: this.$store.getters.getToken,
          id: this.service.id,
          day: this.currentDate,
        })
        .then((response) => {
          if (response.status === 200) {
            this.freeAppointments = response.data.appointments;
            this.freeAppointmentsModal = true;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    saveAppointment() {
      this.errors = {};

      //Error message, empty service
      // if (!this.appointment.service_id) {
      //   this.$toasted.show("Szolgáltatást kötelező választani!", {
      //     position: "bottom-center",
      //     duration: 3500,
      //     fullWidth: true,
      //     fitToScreen: false,
      //     type: "error"
      //     // icon: "checkmark-outline"
      //   });
      // }

      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/update", {
          token: this.$store.getters.getToken,
          id: this.appointment.id,
          service_id: this.$route.params.service_id,
          time_start: this.timeStart,
          time_stop: this.appointment.time_stop,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 3500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    deleteAppointment() {
      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/delete", {
          token: this.$store.getters.getToken,
          id: this.appointment.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({
              name: "ServiceProviderAppointments",
              params: { id: this.$route.params.service_id },
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    modifyTimeStop() {
      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/modifyTimeStop", {
          token: this.$store.getters.getToken,
          id: this.service.id,
          time_start: this.timeStart,
        })
        .then((response) => {
          if (response.status === 200) {
            this.appointment.time_stop = response.data.time_stop;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });

      // console.log("modosult!");
    },
    selectDate(day) {
      this.currentDate = day.date;
      this.datesModal = false;
      this.getAvailabelAppointments();
    },
    selectAppointment(appointment) {
      axios
        .post(this.$store.getters.getApiUrl + "appointment/sp/update", {
          token: this.$store.getters.getToken,
          id: this.appointment.id,
          service_id: this.service.id,
          time_start: this.currentDate + " " + appointment.time_start + ":00",
          time_stop: this.currentDate + " " + appointment.time_stop + ":00",
        })
        .then((response) => {
          if (response.status === 200) {
            //Close modal
            this.freeAppointmentsModal = false;

            //Toaster
            this.$toasted.show("Sikeres módosítás!", {
              position: "bottom-center",
              duration: 3500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });

            //Reload appointment data
            this.getAppointment();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    changeAppointmentState(state) {
      //Check state is changed or not
      if (this.appointment.is_available != state) {
        axios
          .post(this.$store.getters.getApiUrl + "appointment/sp/update/state", {
            token: this.$store.getters.getToken,
            id: this.appointment.id,
            state: state,
          })
          .then((response) => {
            if (response.status === 200) {
              //Toaster
              this.$toasted.show("Sikeres állapot módosítás!", {
                position: "bottom-center",
                duration: 3500,
                fullWidth: true,
                fitToScreen: false,
                type: "success",
                // icon: "checkmark-outline"
              });

              //Reload appointment
              this.getAppointment();

              //Close modal
              this.statesModal = false;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
    this.getAppointment();
  },
};
</script>
