<template>
  <div>
    <Auth />

    <Header
      title="Csoportos munkanap megadás"
      back-route-name="MassWorkday"
      custom-class="bg-active text-light"
    />

    <div class="section mb-2 mt-2 full">
      <div class="wide-block">
        <div class="section inset my-2">
          <div class="row">
            <div class="col-12">
              <span class="blockTitle">Napok listája</span>
            </div>
            <div class="col-12">
              <div class="input-list">
                <div
                  v-for="(day, index) in days"
                  :key="'wb_' + index"
                  class="custom-control custom-checkbox"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="'day-' + index"
                    v-model="checkedDays[index]"
                    @click="clickCheckedDay(index)"
                  />
                  <label class="custom-control-label" :for="'day-' + index">{{
                    day
                  }}</label>
                </div>
              </div>
            </div>
            <div v-show="showTime" class="col-12 my-2">
              <span class="blockTitle">Időpont megadás</span>
            </div>
            <div v-show="showTime" class="col-6">
              <div class="form-group boxed">
                <div class="input-wrapper not-empty">
                  <label class="label ml-1">Kezdés</label>
                  <input
                    type="text"
                    v-model="time_start_show"
                    class="form-control"
                    placeholder="08:00"
                  />

                  <i class="clear-input pb-4 mb-4">
                    <ion-icon
                      name="alarm-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="add outline"
                      @click="openDateTime('start')"
                    ></ion-icon>
                  </i>

                  <datetime
                    type="time"
                    ref="dateTimeStart"
                    v-model="time_start"
                    input-class="form-control"
                    value-zone="Europe/Budapest"
                    zone="Europe/Budapest"
                    :phrases="{ ok: 'Mentés', cancel: 'Mégse' }"
                    format="HH:mm"
                    title="Munkanap kezdés"
                    auto
                  >
                  </datetime>
                  <div class="invalid-feedback" v-if="errors.time_start">
                    {{ errors.time_start[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div v-show="showTime" class="col-6">
              <div class="form-group boxed">
                <div class="input-wrapper not-empty">
                  <label class="label ml-1">Befejezés</label>
                  <input
                    type="text"
                    v-model="time_stop_show"
                    class="form-control"
                    placeholder="17:00"
                  />

                  <i class="clear-input pb-4 mb-4">
                    <ion-icon
                      name="alarm-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="add outline"
                      @click="openDateTime('stop')"
                    ></ion-icon>
                  </i>

                  <datetime
                    type="time"
                    ref="dateTimeStop"
                    v-model="time_stop"
                    input-class="form-control"
                    value-zone="Europe/Budapest"
                    zone="Europe/Budapest"
                    :phrases="{ ok: 'Mentés', cancel: 'Mégse' }"
                    format="HH:mm"
                    title="Munkanap befejezés"
                    auto
                  >
                  </datetime>
                  <div class="invalid-feedback" v-if="errors.time_stop">
                    {{ errors.time_stop[0] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="time_start && time_stop"
            class="row"
            style="margin-top: -30px"
          >
            <div class="col-12 my-2">
              <span class="blockTitle">Hónap megadás</span>
            </div>
            <div
              v-for="(month, index) in months"
              :key="'wm-' + index"
              class="col-4"
            >
              <div
                class="monthButton"
                :class="{
                  activeMonth: checkedSelectedMonth(index),
                  disabledMonth: index <= currentMonth - 1,
                }"
                @click="selectMonth(index)"
              >
                {{ month }}
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div
              class="btn btn-lg btn-block mb-1"
              :class="
                showTime &&
                time_start.length > 1 &&
                time_stop.length > 1 &&
                checkedMonths.length !== 0
                  ? 'btn-primary'
                  : 'btn-disabled'
              "
              @click="saveWorkdays"
            >
              <div v-if="showLoader">
                <lottie-animation
                  ref="anim"
                  :animationData="require('@/assets/img/lottie/loader3.json')"
                  :loop="true"
                />
              </div>
              <div v-if="!showLoader">
                {{ saveButtonTitle }}
              </div>
            </div>
            <div
              class="btn btn-lg btn-primary btn-block mb-1"
              @click="resetData"
              v-show="showResetButton"
            >
              <div>
                <ion-icon
                  name="refresh-outline"
                  role="img"
                  class="md hydrated"
                ></ion-icon>
              </div>
              <div>Újrakezd</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import LottieAnimation from "lottie-web-vue";
import Header from "../../partials/Header";
import Auth from "../../components/Auth";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MassWorkdayFix",
  components: {
    Header,
    Auth,
    LottieAnimation,
  },
  data: () => ({
    time_start: "",
    time_start_show: "",
    time_stop: "",
    time_stop_show: "",
    currentDate: "",
    errors: {},
    days: {},
    checkedDays: {},
    selectedDays: [],
    months: {},
    checkedMonths: [],
    currentMonth: 0,
    timeSync: true,
    showTime: false,
    showDays: false,
    showMonth: false,
    saveButtonTitle: "Mentés",
    showLoader: false,
    showResetButton: false,
  }),
  updated() {},
  computed: { ...mapGetters(["user"]) },
  watch: {
    time_start_show() {
      if (
        this.time_start_show &&
        this.time_start_show.length > 4 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(this.currentDate, this.time_start_show, "start");
      }
    },
    time_stop_show() {
      if (
        this.time_stop_show &&
        this.time_stop_show.length > 4 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(this.currentDate, this.time_stop_show, "stop");
      }
    },
    time_start() {
      if (this.time_start && this.time_start.length > 1 && this.timeSync) {
        //Get synced time
        this.syncDateTime(
          this.currentDate,
          this.time_start,
          "start",
          "chooser"
        );
      }
    },
    time_stop() {
      if (this.time_stop && this.time_stop.length > 1 && this.timeSync) {
        //Get synced time
        this.syncDateTime(this.currentDate, this.time_stop, "stop", "chooser");
      }
    },
  },
  methods: {
    getMainData() {
      axios
        .post(this.$store.getters.getApiUrl + "workday/days-months", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            // console.log("Days - months - resp: ", response.data);
            this.days = response.data.days;
            this.months = response.data.months;
            this.currentDate = response.data.currentDate;
            this.currentMonth = response.data.currentMonth;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    clickCheckedDay(index) {
      this.showTime = false;

      setTimeout(() => {
        Object.values(this.checkedDays).map((day) => {
          if (day === true) {
            this.showTime = true;
          }
        }, 30);
      });

      //Add selected day's index to array
      if (this.selectedDays.indexOf(index) === -1) {
        this.selectedDays.push(index);
      } else {
        this.selectedDays.splice(this.selectedDays.indexOf(index), 1);
      }
    },
    selectMonth(index) {
      //If index not in array we add it else remove it
      if (index < this.currentMonth) return;

      if (this.checkedMonths.indexOf(index) === -1) {
        this.checkedMonths.push(index);
      } else {
        this.checkedMonths.splice(this.checkedMonths.indexOf(index), 1);
      }
    },
    checkedSelectedMonth(index) {
      return this.checkedMonths.indexOf(index) !== -1 ? true : false;
    },
    saveWorkdays() {
      //Validations
      if (!this.showTime) {
        this.$toasted.show("Kérlek válassz ki legalább egy napot!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
          //   icon: "error_outline",
        });

        return;
      }

      if (this.time_start.length < 1 && this.time_stop.length < 1) {
        this.$toasted.show("Kérlek adj meg kezdő és befejezési időt!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
          //   icon: "error_outline",
        });

        return;
      }

      if (this.checkedMonths.length === 0) {
        this.$toasted.show("Kérlek adj meg legalább egy hónapot!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
          //   icon: "error_outline",
        });

        return;
      }

      //Send data to API & log in to dashboard
      axios
        .post(this.$store.getters.getApiUrl + "workday/massCreateMonths", {
          token: this.$store.getters.getToken,
          time_start: this.time_start,
          time_stop: this.time_stop,
          days: this.selectedDays,
          months: this.checkedMonths,
        })
        .then((response) => {
          if (response.status === 200) {
            //Show loader
            this.showLoader = true;

            //Disable loader
            setTimeout(() => {
              //Change button title
              this.saveButtonTitle = "Mentve";
              this.showLoader = false;
              this.showResetButton = true;
            }, 2500);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    resetData() {
      //Reset values
      this.checkedDays = {};
      this.selectedDays = [];
      this.checkedMonths = [];
      this.time_start = "";
      this.time_start_show = "";
      this.time_stop = "";
      this.time_Stop_show = "";
      this.showTime = false;
      this.saveButtonTitle = "Mentés";
      this.showResetButton = false;
    },
    openDateTime(element) {
      if (element == "start") {
        this.$refs.dateTimeStart.isOpen = true;
      }

      if (element == "stop") {
        this.$refs.dateTimeStop.isOpen = true;
      }
    },
    syncDateTime(date = null, time = null, type = "start", from = null) {
      this.timeSync = false;
      axios
        .post(this.$store.getters.getApiUrl + "time/sync", {
          token: this.$store.getters.getToken,
          date: date,
          time: time,
          from: from,
        })
        .then((response) => {
          if (response.status === 200) {
            //FIXME: disable console.log
            // console.log("------------");
            // console.log("Time sync APi resp: ", response.data);

            if (type == "start") {
              this.time_start = response.data.time.dateTime;
              this.time_start_show = response.data.time.time;
            }

            if (type == "stop") {
              this.time_stop = response.data.time.dateTime;
              this.time_stop_show = response.data.time.time;
            }

            //FIXME: disable console.log
            // console.log("Time synced: ", this.time_start, this.time_stop);
            // console.log("------------");

            setTimeout(() => {
              this.timeSync = true;
            }, 250);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    //Get available days
    this.getMainData();
  },
};
</script>
