<template>
  <div>
    <RegistrationHeaderServiceProvider />

    <div class="section mb-2 mt-2 full">
      <div class="wide-block pt-2 pb-2">
        <form ref="form">
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="name1"
                name="name"
                placeholder="Név"
                v-model="registration.name"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback" v-if="errors.name">
                {{ errors.name[0] }}
              </div>
            </div>

            <!--<div class="text-left">-->
            <!--  <small class="text-danger">{{ $message }}</small>-->
            <!--</div>-->
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="email"
                class="form-control"
                id="email1"
                name="email"
                placeholder="Email cím"
                v-model="registration.email"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback" v-if="errors.email">
                {{ errors.email[0] }}
              </div>
            </div>

            <!--<div class="text-left">-->
            <!--  <small class="text-danger">{{ $message }}</small>-->
            <!--</div>-->
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                placeholder="Céges telefonszám"
                v-model="registration.phone"
                :class="{ 'is-invalid': errors.phone }"
              />
              <div class="invalid-feedback" v-if="errors.phone">
                {{ errors.phone[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                id="password1"
                name="password"
                placeholder="Jelszó"
                v-model="registration.password"
                :class="{ 'is-invalid': errors.password }"
              />
              <div class="invalid-feedback" v-if="errors.password">
                {{ errors.password[0] }}
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6 text-left text-white pl-2 regOldEnough">
              Kijelentem, hogy elmúltam 18 éves
            </div>
            <div class="col-6 text-center pr-2">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="oldEnough"
                  id="type"
                />
                <label class="custom-control-label" for="type"></label>
              </div>
            </div>
          </div>

          <div class="wide-block pt-2 pb-2">
            <div class="alert alert-warning mb-2" role="alert">
              A regisztrációval elfogadod a <br />
              <a href="https://fixeet.com/adatkezelesi-tajekoztato/">
                Felhasználási feltételeket
              </a>
            </div>
          </div>

          <div class="form-button-group">
            <div @click="checkForm" class="btn btn-magenta btn-lg btn-block">
              Tovább
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationHeaderServiceProvider from "@/partials/registration/RegistrationHeaderServiceProvider";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderStep1",
  components: { RegistrationHeaderServiceProvider },
  data: () => ({
    apiURL: "",
    registration: {
      name: "",
      password: "",
      email: "",
      phone: "",
      oldEnough: false,
    },
    errors: {},
  }),
  methods: {
    checkForm() {
      //Disable errors
      this.errors = "";

      //Check old enough
      if (!this.oldEnough) {
        //Show error message
        this.$toasted.show("Nem fogadtad el az összes feltételt!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
        });

        return;
      }

      axios
        .post(
          this.$store.getters.getApiUrl + "registration/service_provider_step1",
          {
            email: this.$data.registration.email,
            name: this.$data.registration.name,
            password: this.$data.registration.password,
            phone: this.$data.registration.phone,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("updateServiceProviderRegistrationStep1", {
              registration: this.$data.registration,
            });

            this.$router.push({ name: "ServiceProviderStep2" });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          // - Toaster example
          // this.$toasted.show(this.errors, {
          //   position: "bottom-center",
          //   duration: 1500,
          //   fullWidth: true,
          //   fitToScreen: true,
          //   type: "error",
          //   icon: "error_outline"
          // });
        });
    },
  },
  mounted() {
    this.$store.state.registration.step = 1;
    this.$data.registration = this.$store.getters.getRegistrationData;

    //Add bg class to body
    document.body.classList.add("registrationBgServiceProvider");
  },
  destroyed() {
    //Remove bg class to body
    document.body.classList.remove("registrationBgServiceProvider");
  },
};
</script>
