<template>
  <div></div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "FromNotification",
  components: {},
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    deviceToken: "",
    deviceManufacturer: "",
    errors: {},
  }),
  methods: {
    goNotification() {
      this.errors = "";

      axios
        .post(this.$store.getters.getApiUrl + "login", {
          email: this.form.email,
          password: this.form.password,
          deviceToken: localStorage.getItem("deviceToken"),
          deviceManufacturer: localStorage.getItem("deviceManufacturer"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("loginSuccess", {
              user: response.data.user,
              shop: response.data.shop,
            });

            //Save email & password to localStorage
            localStorage.loginEmail = this.$data.form.email;
            localStorage.loginPassword = this.$data.form.password;
            localStorage.userId = response.data.user.id;

            this.$router.push({ name: "Notification" });
          }
        })
        .catch((error) => {
          console.log("hiba: ", error.response.data);
          this.errors = error.response.data;
        });
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    if (localStorage.loginEmail && localStorage.loginPassword) {
      if (localStorage.loginEmail !== "null") {
        this.form.email = localStorage.loginEmail;
        this.form.password = localStorage.loginPassword;
        this.deviceToken = localStorage.deviceToken;
        this.deviceManufacturer = localStorage.deviceManufacturer;

        this.goNotification();
      } else {
        this.goToLogin();
      }
    }

    //Add bg class to body
    document.body.classList.add("loginBg");
  },
  destroyed() {
    document.body.classList.remove("loginBg");
  },
};
</script>

<style>
</style>
