<template>
  <div>
    <Auth />

    <Header
      title="QR kód"
      muted="Saját kódom"
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <div class="section full text-center mt-3">
      <div class="my-3 p-2">
        <img
          v-if="qrCodeImgUrl"
          :src="qrCodeImgUrl"
          alt="sajat_qr_codom"
          class="img-fluid"
        />
      </div>
    </div>

    <div v-if="qrCodeImgUrl" class="section mt-2 p-3">
      <div class="profile-head">
        <div class="avatar">
          <img
            v-if="user.profileImage"
            :src="user.profileImage"
            :alt="user.name + '_alt'"
            class="imaged w64 rounded"
          />
          <img
            v-else
            :src="require('@/assets/img/profile.jpg')"
            alt="_alt"
            class="imaged w64 rounded"
          />
        </div>
        <div class="in">
          <h3 class="name">{{ user.name }}</h3>
          <h5 class="subtext">
            Email:
            <a :href="'mailto:' + user.email">{{ user.email }}</a>
          </h5>
          <h5 class="subtext mt-1">
            Telefonszám:
            <a :href="'tel:' + user.phone">{{ user.phone }}</a>
          </h5>
          <h5 class="subtext mt-1">
            Cím:
            <a
              :href="
                'https://maps.google.com/?q=' +
                  user.zip +
                  ' ' +
                  user.city +
                  ', ' +
                  user.address
              "
              >{{ user.zip }} {{ user.city }}, {{ user.address }}</a
            >
          </h5>
        </div>
      </div>

      <div class="row text-center mt-2">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary rounded shadowed btn-lg mt-5 mx-auto px-auto"
            @click="$router.push({ name: 'QR_CodeScanner' })"
          >
            <!--<ion-icon name="scan-circle-outline"></ion-icon>-->
            QR KÓD BEOLVASÁS
          </button>
        </div>
      </div>
    </div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import QRious from "qrious/dist/qrious.min";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "QR_Code",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter
  },
  data: () => ({
    errors: {},
    qr_code: null,
    size: 250,
    qrCodeImgUrl: null
  }),
  methods: {
    getQR_Code() {
      axios
        .post(this.$store.getters.getApiUrl + "qrcode/show", {
          token: this.$store.getters.getToken
        })
        .then(response => {
          if (response.status === 200) {
            this.qr_code = response.data.qr_code;
            this.generateQRCode(response.data.qr_code);
          }
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
    generateQRCode(qr_code) {
      this.qrCodeImgUrl = new QRious({
        size: this.size,
        value: qr_code,
        // foreground: "#8fd0b8",
        foreground: "#743FE1",
        background: "#F9F9F9"
      }).toDataURL();

      // console.log(this.qrCodeImgUrl);
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    this.getQR_Code();
  }
};
</script>
