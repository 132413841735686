<template>
  <div>
    <!--<Auth />-->

    <Header
      title="Keresés"
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <!-- <Categories /> -->

    <div class="section full">
      <div class="wide-block pt-2 pb-2">
        <div class="card bg-white text-white">
          <img
            :src="require('@/assets/img/backgrounds/search.jpg')"
            class="card-img overlay-img searchCard"
            alt="searchBg"
          />
          <div class="card-img-overlay searchCardOverlay">
            <div class="search-form searchForm">
              <div class="form-group searchbox">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Szolgáltatás neve..."
                  v-model="search"
                  v-on:keyup.enter="letsSearch"
                />
                <!-- <div
                  class="text-white icon-custom icon-search searchButton"
                ></div> -->
                <i class="input-icon searchButton" @click="letsSearch">
                  <ion-icon
                    name="search-outline"
                    role="img"
                    class="md hydrated"
                    aria-label="search outline"
                  ></ion-icon>
                </i>
              </div>
            </div>
          </div>
        </div>

        <div v-if="shops" class="row align-content-center text-center mt-2">
          <div class="col-12">
            <div
              class="divider my-3"
              :class="{ 'bg-fixeet': visibleViewSelector }"
              @click="visibleViewSelector = !visibleViewSelector"
            >
              <div
                class="icon-box"
                :class="{ 'bg-fixeet': visibleViewSelector }"
              >
                <ion-icon name="reorder-four-outline"></ion-icon>
              </div>
            </div>
          </div>

          <div v-show="visibleViewSelector && cities" class="col-6 pt-1">
            <strong>Szűrés városra:</strong>
          </div>
          <div v-show="visibleViewSelector && cities" class="col-6">
            <button
              type="button"
              class="btn btn-primary rounded shadowed mr-1 mb-1"
              @click="filterModal = true"
            >
              {{ choosedCity.city }}
            </button>
          </div>

          <div v-show="visibleViewSelector" class="col-12 mt-2">
            <strong>Nézet kiválasztása:</strong>
          </div>
          <!--<div v-show="visibleViewSelector" class="col-6 mt-2">-->
          <!--</div>-->

          <div v-show="visibleViewSelector" class="col-6 mt-2">
            <button
              type="button"
              class="btn btn-icon shadowed mr-1 mb-1"
              data-dismiss="modal"
              :class="{
                'btn-primary': view === 'box',
                'btn-outline-primary': view === 'list',
              }"
              @click="view = 'box'"
            >
              <ion-icon name="stop-outline"></ion-icon>
            </button>
          </div>
          <div v-show="visibleViewSelector" class="col-6 mt-2">
            <button
              type="button"
              class="btn btn-icon shadowed mr-1 mb-1"
              data-dismiss="modal"
              :class="{
                'btn-primary': view === 'list',
                'btn-outline-primary': view === 'box',
              }"
              @click="view = 'list'"
            >
              <ion-icon name="list-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!notFound && shopsLength > 0">
      <List v-if="view === 'list'" :shops="shops" />
      <Box v-else-if="view === 'box'" :shops="shops" />
    </div>

    <div v-if="notFound" class="section full">
      <ErrorPanel
        class-label="alert alert-primary mainGreen"
        title="Hoppá!"
        message="Nincsenek a kulcsszavaknak megfelelő találatok!"
        message2="Kérlek próbálj meg más szavakat!"
      />
    </div>

    <!--Modal to change city-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="filterModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton2"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Szűrés városra</h5>
            </div>
            <div class="modal-footer">
              <div class="btn-list">
                <a
                  href="javascript:void(0)"
                  class="btn btn-block"
                  @click="searchWithFilter('0', 'VÁLASSZ')"
                  >SZŰRÉS KIKAPCSOLÁSA</a
                >
                <a
                  v-for="(city, index) in cities"
                  :key="index"
                  href="javascript:void(0)"
                  class="btn btn-block"
                  @click="searchWithFilter(index, city)"
                  >{{ index }} - {{ city }}</a
                >
                <a
                  href="javascript:void(0)"
                  class="btn btn-text-secondary btn-block"
                  @click="filterModal = false"
                  >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import axios from "axios";
// import Categories from "@/partials/Categories";
import List from "@/partials/search/List";
import Box from "@/partials/search/Box";
import ErrorPanel from "@/partials/ErrorPanel";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Search",
  components: {
    ErrorPanel,
    Box,
    List,
    // Categories,
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    categories: {},
    serviceProviders: {},
    shops: {},
    shopsLength: 0,
    cities: {},
    currentCity: "",
    search: "",
    awaitingSearch: false,
    notFound: false,
    view: "box",
    visibleViewSelector: true,
    filterModal: false,
    choosedCity: {
      zip: "0",
      city: "VÁLASSZ",
    },
  }),
  methods: {
    getCities() {
      axios
        .post(this.$store.getters.getApiUrl + "search/get/cities")
        .then((response) => {
          if (response.status === 200) {
            console.log("cities resp: ", response.data.cities);
            this.cities = response.data.cities;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    letsSearch() {
      if (this.search.length > 3) {
        axios
          .post(this.$store.getters.getApiUrl + "search", {
            token: this.$store.getters.getToken,
            search: this.search,
            zip: this.choosedCity.zip,
          })
          .then((response) => {
            if (response.status === 200) {
              this.shops = response.data.shops;
              this.shopsLength = response.data.shops_length;
              this.notFound = response.data.notFound;
              this.cities = response.data.cities;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    getCategories() {
      axios
        .post(this.$store.getters.getApiUrl + "categories/list", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$data.categories = response.data.categories;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    searchWithFilter(zip, city) {
      this.choosedCity.zip = zip;
      this.choosedCity.city = city;
      this.letsSearch();
      this.filterModal = false;
    },
  },
  watch: {
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.letsSearch();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
  },
  mounted() {
    //If we put a search queue run the search
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.letsSearch();
    }

    this.getCities();

    //Add bg class to body
    // document.body.classList.add("searchBg");
  },
  destroyed() {
    // document.body.classList.remove("searchBg");
  },
};
</script>
