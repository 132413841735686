import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/style.css";
import "@/assets/css/calendar.css";
// import "vue-material-design-icons/styles.css";
import Toasted from "vue-toasted";
import "vue2-animate/dist/vue2-animate.min.css";

import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";

import VCalendar from "v-calendar";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

// import "material-icons/iconfont/material-icons.scss";
// import "@fortawesome/fontawesome-free/css/all.css";
// import "@fortawesome/fontawesome-free/js/all.js";
// import "@/assets/js/lib/jquery-3.4.1.min.js";
// import "@/assets/js/base.js";


//Toaster config
Vue.use(Toasted, {
  iconPack: "material"
});

//v-Calendar config
Vue.use(VCalendar, {
  componentPrefix: "vc",
  headWeekDay: ["H", "K", "Sze", "Cs", "P", "Szo", "V"]
});

// Datetime plugin config
Vue.use(Datetime);
Vue.component("datetime", Datetime);

//ION Icon config
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
