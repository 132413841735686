<template>
  <div
    class="bgDashboard mainDashboard disableYPadding"
    style="position: relative"
  >
    <!--<Auth />-->
    <div class="row px-4">
      <div class="col-1 text-left">
        <div class="icon-custom icon-menu-white" @click="updateMenuState"></div>
        <span
          v-if="
            (notificationsCounter > 0 || contactCounter > 0) &&
            notificationsCounter + contactCounter >= 10
          "
          class="badge badge-pill badge-active badge-dashboard"
          >9+
        </span>
        <span
          v-else-if="
            notificationsCounter > 0 ||
            (contactCounter > 0 && notificationsCounter + contactCounter < 10)
          "
          class="badge badge-pill badge-active badge-dashboard"
          >{{ notificationsCounter + contactCounter }}
        </span>
      </div>
    </div>
    <!-- <Header title="Főoldal" :transparent="true" /> -->

    <div class="header-title dashboardTitle p-3">
      <h3 v-if="loggedIn" class="text-light">Üdv újra a F!xeet-ben!</h3>
      <h3 v-else class="text-light">Üdvözöllek a F!xeet-ben!</h3>
      <div class="subtitle dashboardSubtitle">
        Találd meg a kedvenc szolgáltatóidat és foglalj időpontot akár
        <strong>30 mp</strong> alatt!
      </div>
    </div>

    <div
      v-if="loggedIn && user.email_verified == 0"
      class="section full my-3 text-center"
    >
      <div class="wide-block">
        <div class="alert alert-danger" role="alert">
          <h4 class="alert-title">😮 Fiókod még nincs aktiválva 🤔</h4>
        </div>
      </div>
    </div>

    <Categories />
    <Nav />
    <NavFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import Categories from "../partials/Categories";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    // Cards,
    Categories,
    Nav,
    NavFooter,
  },
  data: () => ({
    user: {},
    showTutorialModal: false,
  }),
  methods: {
    ...mapActions(["updateMenuState", "updateDarkMode"]),
    updateDarkModeFunction() {
      this.$store.commit("updateDarkMode");

      const el = document.body;

      if (this.user.darkMode) {
        el.classList.add("dark-mode-active");
      } else {
        el.classList.remove("dark-mode-active");
      }
    },
    openMenu() {
      this.$store.commit("updateMenu", {
        show: this.menuShow,
      });
    },
  },
  computed: {
    ...mapGetters([
      "loggedIn",
      "menuShow",
      "notificationsCounter",
      "contactCounter",
    ]),
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
  },
  beforeUnmount() {},
};
</script>

<style>
</style>
