<template>
  <div>
    <Auth />

    <Header
      title="QR kód"
      muted="Beolvasás"
      back-route-name="QR_Code"
      custom-class="bg-active text-light"
    />

    <div class="mt-3 text-center section full">
      <div class="p-2 my-3">
        <input
          type="file"
          accept="image/*;capture=camera"
          id="qrCodeTestInput"
          style="display:none"
          v-on:change="refreshQrTestInput"
        />

        <qrcode-stream v-if="enableQrcodeReader" @decode="getQR_Code" />

        <div v-else class="text-center row">
          <div class="col-12">
            <button
              type="button"
              class="mx-auto mt-2 rounded w-100 btn btn-success mainGreen shadowed btn-lg px-auto"
              @click="reReadQrcode"
            >
              <ion-icon name="reload-circle-outline"></ion-icon>
              ÚJ QR KÓD BEOLVASÁS
            </button>
          </div>
          <div class="col-12">
            <button
              type="button"
              class="mx-auto mt-2 rounded w-100 btn btn-success mainGreen shadowed btn-lg px-auto"
              onclick="document.getElementById('qrCodeTestInput').click()"
            >
              <ion-icon name="reload-circle-outline"></ion-icon>
              FÉNYKÉPEZÉS
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="success" class="p-3 mt-2 section">
      <div class="profile-head">
        <div class="avatar">
          <img
            v-if="scannedUser.profile_image"
            :src="scannedUser.profile_image"
            :alt="scannedUser.name + '_alt'"
            class="rounded imaged w64"
          />
          <img
            v-else
            :src="require('@/assets/img/profile.jpg')"
            alt="_alt"
            class="rounded imaged w64"
          />
        </div>
        <div class="in">
          <h3 class="name">{{ scannedUser.name }}</h3>
          <h5 class="subtext">
            Email:
            <a :href="'mailto:' + scannedUser.email">{{ scannedUser.email }}</a>
          </h5>
          <h5 class="mt-1 subtext">
            Telefonszám:
            <a :href="'tel:' + scannedUser.phone">{{ scannedUser.phone }}</a>
          </h5>
          <h5 class="mt-1 subtext">
            Cím:
            <a
              :href="
                'https://maps.google.com/?q=' +
                  scannedUser.zip +
                  ' ' +
                  scannedUser.city +
                  ', ' +
                  scannedUser.address
              "
              >{{ scannedUser.zip }} {{ scannedUser.city }},
              {{ scannedUser.address }}</a
            >
          </h5>
        </div>
      </div>

      <div class="mt-2 text-center row">
        <div class="my-4 col-12">
          <input type="file" accept="image/*;capture=camera" />
        </div>
        <div class="col-12">
          <button
            type="button"
            class="mx-auto mt-5 rounded w-75 btn btn-primary shadowed btn-lg px-auto"
            @click="
              $router.push({
                name: 'SearchSPServices',
                params: { id: scannedUser.id }
              })
            "
          >
            <ion-icon name="list-circle-outline"></ion-icon>
            SZOLGÁLTATÁSOK
          </button>
        </div>
        <div class="col-12">
          <button
            type="button"
            class="mx-auto mt-1 rounded w-75 btn btn-primary shadowed btn-lg px-auto"
          >
            <ion-icon name="add-circle-outline"></ion-icon>
            ÚJ KEDVENC
          </button>
        </div>
      </div>
    </div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import { QrcodeStream } from "vue-qrcode-reader";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "QR_CodeScanner",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
    QrcodeStream
  },
  data: () => ({
    success: "",
    errors: {},
    qr_code: null,
    size: 250,
    enableQrcodeReader: false,
    scannedUser: {},
    qrCodeTestInput: null
  }),
  methods: {
    getQR_Code(decodedString) {
      axios
        .post(this.$store.getters.getApiUrl + "qrcode/read", {
          token: this.$store.getters.getToken,
          qr_code: decodedString
        })
        .then(response => {
          if (response.status === 200) {
            this.qr_code = decodedString;
            this.enableQrcodeReader = false;
            this.scannedUser = response.data.scannedUser;
            this.success = response.data.success;

            console.log(response.data);
          }
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
    reReadQrcode() {
      this.qr_code = null;
      this.enableQrcodeReader = true;
      this.success = "";
    },
    refreshQrTestInput(e) {
      const file = e.target.files[0];
      // this.user.profileImage = URL.createObjectURL(file);
      this.qrCodeTestInput = file;
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    // let message = {
    //   action: "camera"
    // };
    // if (!window.webkit.messageHandlers.cordova_iab) {
    //   console.log("Cordova IAB postMessage API not found!");
    // } else {
    //   window.webkit.messageHandlers.cordova_iab.postMessage(
    //     JSON.stringify(message)
    //   );
    // }
  }
};
</script>
