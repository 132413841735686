<template><div></div></template>

<script>
import axios from "axios";

export default {
  methods: {
    checkToken() {
      // if (this.loggedIn) {
      axios
        .post(this.$store.getters.getApiUrl + "refreshToken", {
          token: this.$store.getters.getToken
        })
        .then(response => {
          this.$store.commit("refreshToken", {
            token: response.data.token,
            loggedIn: true,
            user: response.data.user
          });
        })
        .catch(error => {
          console.log("Error!", error);
          this.$store.commit("refreshToken", {
            token: null,
            loggedIn: false,
            user: ""
          });

          this.$router.push({ name: "Home" });
          // this.$router.go(-1);
        });
      // }
    }
  },
  template: " ",
  computed: {
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    }
  },
  mounted() {
    this.checkToken();
  }
};
</script>
