<template>
  <div class="section full my-1 text-center">
    <!-- <h4 class="subtitle pl-2 pb-2 text-light"> -->
    <!-- VÁLASSZ KATEGÓRIÁT! -->
    <!-- </h4> -->
    <div
      id="carousel1"
      class="carousel-multiple owl-carousel owl-theme owl-loaded owl-drag"
    >
      <div v-for="(category, index) in categories" :key="index" class="item">
        <div
          class="card"
          @click="
            $router.push({ name: 'Category', params: { id: category.id } })
          "
          :style="{ backgroundImage: `url(${category.filename})` }"
        >
          <div class="card-body" :class="randomColor(category.id)">
            <div class="card-title">
              {{ category.title }} <br />
              VÁLASZT
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="carousel2"
      class="carousel-multiple owl-carousel owl-carousel-blue owl-theme owl-loaded owl-drag my-3 pb-5"
    >
      <div v-for="(item, index) in news" :key="'n-' + index" class="item">
        <div
          class="card2"
          @click="$router.push({ name: 'Faq' })"
          :style="{ backgroundImage: `url(${item.filename})` }"
          style="background-size: cover; background-position: center"
        ></div>
        <!-- <div
          class="card2"
          @click="openNewsModalAndLoadData(item.id)"
          :style="{ backgroundImage: `url(${item.filename})` }"
          style="background-size: cover; background-position: center"
        ></div> -->
      </div>
    </div>

    <!-- News modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
      :duration="750"
    >
      <div
        v-if="newsModal"
        class="modal fade modalbox show"
        id="ModalBasic"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header text-black-50">
              <h5
                class="modal-title text-black-50"
                v-html="newsData.title"
              ></h5>
              <div>
                <div
                  @click="newsModal = false"
                  class="icon-custom icon-close-circle"
                ></div>
              </div>
            </div>
            <div class="modal-body">
              <p class="text-justify p-2" v-html="newsData.description"></p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
// import "owl.carousel";

// jQuery kókányolás a owlcarousel miatt
global.jQuery = require("jquery");
let $ = global.jQuery;
window.$ = $;
let owl_carousel = require("owl.carousel");
window.fn = owl_carousel;

export default {
  name: "Categories",
  data: () => ({
    categories: {},
    news: {},
    newsModal: false,
    newsData: {},
  }),
  components: {},
  computed: {},
  props: {},
  methods: {
    getCategories() {
      axios
        .post(this.$store.getters.getApiUrl + "categories/list", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.categories = response.data.categories;
            this.news = response.data.news;

            //Init owl carousel
            this.$nextTick(() => {
              $(this.$el)
                .find("#carousel1")
                .owlCarousel({
                  stagePadding: 32,
                  loop: true,
                  margin: 16,
                  nav: false,
                  items: 2,
                  dots: true,
                  responsiveClass: true,
                  autoplay: true,
                  autoplayTimeout: 3500,
                  autoplayHoverPause: true,
                  responsive: {
                    0: {
                      items: 2,
                    },
                    768: {
                      items: 4,
                    },
                  },
                });
            });

            this.$nextTick(() => {
              $(this.$el)
                .find("#carousel2")
                .owlCarousel({
                  stagePadding: 32,
                  loop: true,
                  margin: 16,
                  nav: false,
                  items: 1,
                  dots: true,
                  autoplay: true,
                  autoplayTimeout: 4500,
                  autoplayHoverPause: true,
                  responsiveClass: true,
                  responsive: {
                    0: {
                      items: 1,
                    },
                    768: {
                      items: 2,
                    },
                  },
                });
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    randomColor() {
      const number = Math.floor(Math.random() * 4);
      return {
        "card-orange": number === 1,
        "card-blue": number === 2 || number === 0,
        "card-green": number === 3,
      };
    },
    openNewsModalAndLoadData(id) {
      axios
        .post(this.$store.getters.getApiUrl + "categories/news/data", {
          token: this.$store.getters.getToken,
          id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.newsData = response.data.news;
            this.newsModal = true;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
