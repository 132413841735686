<template>
  <div>
    <Auth />

    <Header
      title="Új"
      muted="szolgáltatás"
      back-route-name="MyServices"
      back-route-param=""
      back-route-id="0"
      custom-class="bg-active text-light"
    />

    <div class="section mt-2">
      <form id="form1" action="#" enctype="multipart/form-data" method="post">
        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1" for="title">Megnevezés</label>
            <input
              type="text"
              class="form-control"
              id="title"
              name="title"
              placeholder="Megnevezés"
              v-model="service.title"
              :class="{ 'is-invalid': errors.title }"
            />
            <div class="invalid-feedback" v-if="errors.title">
              {{ errors.title[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1" for="price">Ár</label>
            <input
              type="text"
              class="form-control"
              id="price"
              name="price"
              placeholder="Ár (HUF)"
              v-model="service.price"
              :class="{ 'is-invalid': errors.price }"
            />
            <div class="invalid-feedback" v-if="errors.price">
              {{ errors.price[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1" for="duration">Időtartam</label>
            <input
              type="text"
              class="form-control"
              id="duration"
              name="duration"
              placeholder="Időtartam (perc)"
              v-model="service.duration"
              :class="{ 'is-invalid': errors.duration }"
            />
            <div class="invalid-feedback" v-if="errors.duration">
              {{ errors.duration[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1 strong" for="day_limit"
              >Napi limit ({{
                service.day_limit === "0" || service.day_limit === ""
                  ? "Nincs limit"
                  : service.day_limit + " alkalom naponta"
              }})</label
            >
            <input
              type="text"
              class="form-control"
              id="day_limit"
              name="day_limit"
              :placeholder="
                service.day_limit === 0 || service.day_limit === ''
                  ? 'Nincs limit'
                  : service.day_limit + ' alkalom naponta'
              "
              v-model="service.day_limit"
              :class="{ 'is-invalid': errors.day_limit }"
            />
            <div class="invalid-feedback" v-if="errors.day_limit">
              {{ errors.day_limit[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1" for="description">Kategória</label>
            <div class="input-list">
              <div
                class="custom-control custom-checkbox"
                v-for="(category, index) in categories"
                :key="index"
              >
                <input
                  type="checkbox"
                  :id="'category_' + category.id"
                  :value="category.id"
                  class="custom-control-input"
                  v-model="selectedCategories"
                />
                <label
                  :for="'category_' + category.id"
                  class="custom-control-label"
                  >{{ category.title }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <label class="label ml-1" for="description">Rövid leírás</label>
            <textarea
              type="text"
              rows="5"
              class="form-control"
              id="description"
              name="description"
              placeholder="Rövid leírás"
              v-model="service.description"
              :class="{ 'is-invalid': errors.description }"
            />
            <div class="invalid-feedback" v-if="errors.description">
              {{ errors.description[0] }}
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="section full">
      <div class="wide-block pt-2 pb-2">
        <button
          @click="saveService"
          type="button"
          class="btn btn-success btn-lg btn-block"
        >
          <ion-icon name="save-outline"></ion-icon>
          Mentés
        </button>
      </div>
    </div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MyServiceEdit",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter
  },
  data: () => ({
    errors: {},
    service: {
      title: "",
      price: "",
      duration: "",
      description: "",
      day_limit: ""
    },
    user: {},
    selectedCategories: [],
    categories: {}
  }),
  methods: {
    getCategories() {
      axios
        .post(this.$store.getters.getApiUrl + "service/sp/create", {
          token: this.$store.getters.getToken
        })
        .then(response => {
          if (response.status === 200) {
            this.categories = response.data.categories;
          }
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
    saveService() {
      this.errors = {};

      axios
        .post(this.$store.getters.getApiUrl + "service/sp/store", {
          token: this.$store.getters.getToken,
          title: this.service.title,
          price: this.service.price,
          duration: this.service.duration,
          description: this.service.description,
          day_limit: this.service.day_limit,
          categories: this.selectedCategories
        })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: "MyServices" });
          }
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
    this.getCategories();
  }
};
</script>
