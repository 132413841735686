<template>
  <div>
    <!--<Auth />-->

    <Header
      :title="category.title"
      muted="kategória"
      back-route-name="Search"
      custom-class="bg-active text-light"
    />

    <div v-if="shops && shops.length > 0" class="section inset">
      <div
        class="wide-block bg-white bg-transparent-90 pt-2 pb-2 mt-1"
        v-for="(sp, index) in shops"
        :key="index"
        href="javascript:void(0)"
        @click="
          $router.push({ name: 'SearchShopServices', params: { id: sp.id } })
        "
      >
        <div class="content-header mb-05 text-black-100">
          {{ sp.zip }} {{ sp.city }}, {{ sp.address }}
        </div>

        <div class="row">
          <div class="col-3">
            <div class="avatar">
              <div
                v-if="sp.profile_image"
                :style="{
                  backgroundImage: 'url(' + sp.profile_image + ')',
                }"
                class="profileImage"
              ></div>
              <div
                v-else
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/img/profile.jpg') + ')',
                }"
                class="profileImage"
              ></div>
            </div>
          </div>
          <div class="col-9">
            {{ sp.name }}

            <div
              class="d-inline-block text-primary text-right float-right"
              style="font-size: 2rem; margin-top: -1.5rem"
            >
              <!--<ion-icon name="chevron-forward-circle-outline"></ion-icon>-->
              <div class="icon-custom icon-right-arrow"></div>
            </div>

            <div class="content-footer mt-05 text-right">
              <div class="row">
                <div class="col-6 text-left text-black-100">
                  Tel: {{ sp.phone }} <br />
                  Email: {{ sp.email }}
                </div>
                <div class="col-6 text-right">
                  <!--Időtartam: {{ service.duration }} perc <br />-->
                  <!--Ár: {{ service.price }} HUF-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div v-if="services && services.length > 0" class="section inset">-->
    <!--  <div-->
    <!--    class="wide-block pt-2 pb-2 mt-1"-->
    <!--    v-for="(service, index) in services"-->
    <!--    :key="index"-->
    <!--    href="javascript:void(0)"-->
    <!--    @click="$router.push({ name: 'Service', params: { id: service.id } })"-->
    <!--  >-->
    <!--    <div class="content-header mb-05">-->
    <!--      {{ service.user.name }}-->
    <!--    </div>-->
    <!--    {{ service.title }}-->

    <!--    <div-->
    <!--      class="d-inline-block text-primary text-right float-right"-->
    <!--      style="font-size: 2rem; margin-top: -1.5rem;"-->
    <!--    >-->
    <!--      <ion-icon name="chevron-forward-circle-outline"></ion-icon>-->
    <!--    </div>-->

    <!--    <div class="content-footer mt-05 text-right">-->
    <!--      Ár: {{ service.price }} Ft <br />-->
    <!--      Időtartam: {{ service.duration }} perc-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->

    <div v-else class="section full">
      <ErrorPanel
        title="Hoppá!"
        class-label="alert alert-primary mainGreen"
        message="Nincsenek elérhető szolgáltatások ebben a kategóriában!"
        message2="Kérlek nézz vissza kicsit később!"
      />
    </div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Category",
  components: {
    ErrorPanel,
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    category: {},
    services: {},
    serviceProviders: {},
    shops: {},
  }),
  methods: {
    getCategories() {
      axios
        .post(this.$store.getters.getApiUrl + "categories/show", {
          token: this.$store.getters.getToken,
          id: this.$route.params.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.category = response.data.category;
            this.shops = response.data.shops;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.getCategories();

    this.$store.commit("updateCurrentCategoryId", {
      currentCategoryId: this.$route.params.id,
    });

    //Add bg class to body
    document.body.classList.add("categoryBg");
  },
  destroyed() {
    document.body.classList.remove("categoryBg");
  },
};
</script>
