<template>
  <div>
    <div class="appHeader text-dark" :class="customClass">
      <div class="left">
        <a
          v-if="backRouteName"
          @click="goBackTo()"
          href="javascript:void(0)"
          class="headerButton"
          data-toggle="modal"
          data-target="#sidebarPanel"
        >
          <!--<ion-icon name="chevron-back-outline"></ion-icon>-->
          <div class="icon-custom icon-chevron-back-white"></div>
        </a>
        <a
          v-else
          @click="updateMenuState"
          href="javascript:void(0)"
          class="headerButton text-primary"
          data-toggle="modal"
          data-target="#sidebarPanel"
        >
          <!--<ion-icon name="menu-outline"></ion-icon>-->
          <div class="icon-custom icon-menu"></div>
          <span
            v-if="
              notificationsCounter > 0 ||
              (contactCounter > 0 && notificationsCounter + contactCounter < 10)
            "
            class="badge badge-pill badge-active"
            >{{ notificationsCounter + contactCounter }}
          </span>
          <span
            v-if="
              (notificationsCounter > 0 || contactCounter > 0) &&
              notificationsCounter + contactCounter >= 10
            "
            class="badge badge-pill badge-active"
            >9+
          </span>
        </a>
      </div>
      <div class="pageTitle text-center">
        {{ transparent ? "" : title }}
        <div v-if="muted" class="small muted">{{ muted }}</div>
      </div>
      <div v-if="!backRouteName && !transparent" class="right">
        <a
          href="javascript:;"
          class="headerButton toggle-searchbox text-primary"
          @click="searchBar = true"
        >
          <div class="icon-custom icon-search-top"></div>
        </a>
      </div>
    </div>

    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInDown"
      leave-active-class="animated slideOutUp"
      :duration="750"
    >
      <div v-show="searchBar" id="search" class="appHeader show">
        <form class="search-form" @submit="goToSearchWithQuery">
          <div class="form-group searchbox">
            <input
              type="text"
              class="form-control"
              placeholder="Milyen szolgáltatást keresel?"
              v-model="searchQuery"
              @keyup.enter="goToSearchWithQuery"
            />
            <!--<i class="input-icon text-primary">-->
            <!--  <ion-icon-->
            <!--    name="search-outline"-->
            <!--    role="img"-->
            <!--    class="md hydrated"-->
            <!--    aria-label="search outline"-->
            <!--  ></ion-icon>-->
            <!--</i>-->
            <div class="input-icon-custom icon-custom icon-search-top"></div>
            <a
              href="javascript:;"
              class="ml-1 close toggle-searchbox text-primary"
              @click="searchBar = false"
            >
              <!--<ion-icon-->
              <!--  name="close-circle"-->
              <!--  role="img"-->
              <!--  class="md hydrated"-->
              <!--  aria-label="close circle"-->
              <!--&gt;</ion-icon>-->
              <div class="icon-custom icon-close-circle-gold"></div>
            </a>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Header",
  data: () => ({
    searchBar: false,
    searchQuery: "",
  }),
  components: {},
  computed: {
    ...mapGetters(["notificationsCounter", "contactCounter"]),
    menuShow() {
      return this.$store.getters.getMenuState;
    },
    darkMode() {
      return this.$store.getters.getDarkModeState;
    },
  },
  props: {
    title: String,
    backRouteName: String,
    backRouteParam: String,
    backRouteId: Number,
    muted: String,
    customClass: String,
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["updateMenuState"]),
    openMenu() {
      this.$store.commit("updateMenu", {
        show: this.menuShow,
      });
    },
    goBackTo() {
      if (this.backRouteParam === "id" && this.backRouteId) {
        // console.log("header id: ", this.backRouteId);
        let id = this.backRouteId;
        this.$router.push({ name: this.backRouteName, params: { id: id } });
      } else {
        this.$router.push({ name: this.backRouteName });
      }
    },
    goToSearchWithQuery() {
      this.$router.push({
        name: "Search",
        params: { search: this.searchQuery },
      });
    },
  },
};
</script>
