<template>
  <div>
    <RegistrationHeaderServiceProvider />

    <div class="section mb-2 mt-2 full">
      <div class="wide-block pt-2 pb-2">
        <form id="form1" action="#" enctype="multipart/form-data" method="post">
          <div class="picture-container">
            <div class="picture">
              <img
                id="wizardPicturePreview"
                class="picture-src"
                :src="registration.profileImage"
                title="profil kép"
                onclick="document.getElementById('wizard-picture').click()"
              />
              <input
                id="wizard-picture"
                class=""
                name="profilePhoto"
                type="file"
                v-on:change="refreshProfileImage"
              />
              <div class="fab-button animate profilePhotoEdit">
                <div
                  id="profilePhotoEditBtn"
                  class="fab profilePhotoEditButton"
                >
                  <ion-icon
                    aria-label="add outline"
                    class=""
                    name="camera-outline"
                    role="img"
                  ></ion-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="shopName"
                name="shopName"
                placeholder="Cég neve"
                v-model="registration.shopName"
                :class="{ 'is-invalid': errors.shopName }"
              />
              <div class="invalid-feedback" v-if="errors.shopName">
                {{ errors.shopName[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="shopEmail"
                name="shopEmail"
                placeholder="Céges email"
                v-model="registration.shopEmail"
                :class="{ 'is-invalid': errors.shopEmail }"
              />
              <div class="invalid-feedback" v-if="errors.shopEmail">
                {{ errors.shopEmail[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="shopPhone"
                name="shopPhone"
                placeholder="Céges telefonszám"
                v-model="registration.shopPhone"
                :class="{ 'is-invalid': errors.shopPhone }"
              />
              <div class="invalid-feedback" v-if="errors.shopPhone">
                {{ errors.shopPhone[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="zip"
                placeholder="Irányítószám"
                v-model="registration.zip"
                :class="{ 'is-invalid': errors.zip }"
              />
              <div class="invalid-feedback" v-if="errors.zip">
                {{ errors.zip[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="city"
                placeholder="Város"
                v-model="registration.city"
                :class="{ 'is-invalid': errors.city }"
              />
              <div class="invalid-feedback" v-if="errors.city">
                {{ errors.city[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="address"
                placeholder="Cím"
                v-model="registration.address"
                :class="{ 'is-invalid': errors.address }"
              />
              <div class="invalid-feedback" v-if="errors.address">
                {{ errors.address[0] }}
              </div>
            </div>
          </div>

          <div class="form-button-group">
            <div class="btn btn-magenta btn-lg btn-block" @click="checkForm">
              Tovább
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import RegistrationHeaderServiceProvider from "../../partials/registration/RegistrationHeaderServiceProvider";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderStep2",
  data: () => ({
    registration: {
      profileImage: "../assets/img/profile.jpg",
      zip: "",
      city: "",
      address: "",
      shopName: "",
      shopEmail: "",
      shopPhone: "",
    },
    uploadedFile: "",
    errors: {},
  }),
  components: {
    RegistrationHeaderServiceProvider,
  },
  methods: {
    checkForm() {
      this.errors = "";

      let data = new FormData();
      data.append("email", this.registration.email);
      data.append("name", this.registration.name);
      data.append("password", this.registration.password);
      data.append("zip", this.registration.zip);
      data.append("city", this.registration.city);
      data.append("address", this.registration.address);
      data.append("phone", this.registration.phone);
      data.append("shopName", this.registration.shopName);
      data.append("shopEmail", this.registration.shopEmail);
      data.append("shopPhone", this.registration.shopPhone);
      data.append("uploadedFile", this.uploadedFile);

      axios
        .post(
          this.$store.getters.getApiUrl + "registration/service_provider_step2",
          data
        )
        .then((response) => {
          if (response.status === 200) {
            //Store login credentials
            this.$store.commit("loginSuccess", {
              user: response.data.user,
              shop: response.data.shop,
            });

            //Store selected categories
            this.$store.commit("updateRegistrationCategories", {
              categories: response.data.categories,
            });

            //Save email & password to localStorage
            localStorage.loginEmail = this.$data.registration.email;
            localStorage.loginPassword = this.$data.registration.password;

            //Go tothe next step -> 4
            this.$router.push({ name: "ServiceProviderStep3" });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    refreshProfileImage(e) {
      const file = e.target.files[0];
      this.registration.profileImage = URL.createObjectURL(file);
      this.uploadedFile = file;
    },
  },
  mounted() {
    this.$store.state.registration.step = 2;
    this.$data.registration = this.$store.getters.getRegistrationData;

    //Add bg class to body
    document.body.classList.add("registrationBgServiceProvider");
  },
  destroyed() {
    //Remove bg class to body
    document.body.classList.remove("registrationBgServiceProvider");
  },
};
</script>
