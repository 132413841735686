<template>
  <div>
    <Auth />

    <Header
      title="Csoportos munkanap megadás"
      back-route-name="MassWorkday"
      custom-class="bg-active text-light"
    />

    <!-- <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        @click="saveWorkdays"
        class="fab"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <ion-icon
          name="save-outline"
          role="img"
          class="md hydrated"
          aria-label="save outline"
        ></ion-icon>
      </a>
    </div> -->

    <div class="section mb-2 mt-2 full">
      <div class="section-title pt-2">Munkanapok</div>
      <div class="wide-block">
        <form id="form1" action="#" enctype="multipart/form-data" method="post">
          <div class="section inset my-4" v-if="Object.keys(checkedDays) < 1">
            <div class="alert alert-warning mb-1" role="alert">
              A folytatáshoz, kérlek adj hozzá legalább egy munkanapot!
            </div>
          </div>

          <div v-else class="section inset my-2">
            <div class="row">
              <div class="col-12 p-2">
                <h4>Kiválasztott napok:</h4>
                <div
                  v-for="(checkedDay, index) in checkedDays"
                  :key="'cd_' + index"
                  class="small"
                  v-show="checkedDay"
                >
                  {{ index }}
                </div>
              </div>
              <div class="col-12">
                <div class="form-group boxed">
                  <div class="input-wrapper not-empty">
                    <label class="label ml-1">Kezdés</label>
                    <input
                      type="text"
                      v-model="time_start_show"
                      class="form-control"
                      placeholder="08:00"
                    />

                    <i class="clear-input pb-4 mb-4">
                      <ion-icon
                        name="alarm-outline"
                        role="img"
                        class="md hydrated"
                        aria-label="add outline"
                        @click="openDateTime('start')"
                      ></ion-icon>
                    </i>

                    <datetime
                      type="time"
                      ref="dateTimeStart"
                      v-model="time_start"
                      input-class="form-control"
                      value-zone="Europe/Budapest"
                      zone="Europe/Budapest"
                      :phrases="{ ok: 'Mentés', cancel: 'Mégse' }"
                      format="HH:mm"
                      title="Munkanap kezdés"
                      auto
                    >
                    </datetime>
                    <div class="invalid-feedback" v-if="errors.time_start">
                      {{ errors.time_start[0] }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group boxed">
                  <div class="input-wrapper not-empty">
                    <label class="label ml-1">Befejezés</label>
                    <input
                      type="text"
                      v-model="time_stop_show"
                      class="form-control"
                      placeholder="17:00"
                    />

                    <i class="clear-input pb-4 mb-4">
                      <ion-icon
                        name="alarm-outline"
                        role="img"
                        class="md hydrated"
                        aria-label="add outline"
                        @click="openDateTime('stop')"
                      ></ion-icon>
                    </i>

                    <datetime
                      type="time"
                      ref="dateTimeStop"
                      v-model="time_stop"
                      input-class="form-control"
                      value-zone="Europe/Budapest"
                      zone="Europe/Budapest"
                      :phrases="{ ok: 'Mentés', cancel: 'Mégse' }"
                      format="HH:mm"
                      title="Munkanap befejezés"
                      auto
                    >
                    </datetime>
                    <div class="invalid-feedback" v-if="errors.time_stop">
                      {{ errors.time_stop[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-button-group">
            <div>
              <div
                @click="showDateModal = true"
                class="btn btn-success btn-lg btn-block mt-1"
              >
                Munkanapok megadása
              </div>
              <div
                @click="saveWorkdays"
                class="btn btn-primary btn-lg btn-block mb-1"
              >
                Mentés
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--Modal to show an appointment-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div
        v-show="showDateModal"
        class="modal fade modalbox show"
        id="ModalCheckbox"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Válassz egy napot</h5>
              <a
                href="javascript:void(0);"
                @click="showDateModal = false"
                data-dismiss="modal"
                >Bezár</a
              >
            </div>
            <div class="modal-body p-0">
              <div class="input-list">
                <div
                  v-for="(availableDay, index) in availableDays"
                  :key="'wb_' + index"
                  class="custom-control custom-checkbox"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="'date-' + availableDay.date"
                    v-model="
                      checkedDays[availableDay.date + ' - ' + availableDay.name]
                    "
                  />
                  <label
                    class="custom-control-label"
                    :for="'date-' + availableDay.date"
                    >{{ availableDay.date }} - {{ availableDay.name }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import Header from "../../partials/Header";
import Auth from "../../components/Auth";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MassWorkdayFix",
  data: () => ({
    user: "",
    workdays: [],
    errors: {},
    availableDays: {},
    showDateModal: false,
    checkedDays: {},
    time_start: "",
    time_start_show: "",
    time_stop: "",
    time_stop_show: "",
    currentDate: "",
    timeSync: true,
  }),
  components: {
    Header,
    Auth,
  },
  mounted() {
    //Get the auth user
    this.user = this.$store.getters.getUserData;

    //Get available days
    this.getAvailableDays();
  },
  updated() {
    // console.log("checkedDays: ", Object.keys(this.checkedDays));
  },
  computed: {},
  watch: {
    // time_start() {
    //   if (this.time_start.length > 1) {
    //     let dt = new Date(this.time_start);

    //     dt = dt.toLocaleTimeString(navigator.language, {
    //       hour: "2-digit",
    //       minute: "2-digit",
    //     });
    //     this.time_start_show = dt;
    //   }
    // },
    // time_start_show() {
    //   if (this.time_start_show && this.time_start_show.length > 4) {
    //     let dt = new Date(this.currentDate + "T" + this.time_start_show);
    //     console.log(
    //       "time_start_show - ",
    //       dt.toLocaleString("hu-HU", { timeZone: "Europe/Budapest" })
    //     );
    //     this.time_start = dt.toLocaleString("hu-HU", {
    //       timeZone: "Europe/Budapest",
    //     });
    //   }
    // },
    // time_stop() {
    //   if (this.time_stop.length > 1) {
    //     let dt = new Date(this.time_stop);

    //     dt = dt.toLocaleTimeString(navigator.language, {
    //       hour: "2-digit",
    //       minute: "2-digit",
    //     });
    //     this.time_stop_show = dt;
    //   }
    // },
    // time_stop_show() {
    //   if (this.time_stop_show && this.time_stop_show.length > 4) {
    //     let dt = new Date(this.currentDate + "T" + this.time_stop_show);
    //     console.log(
    //       "time_stop_show - ",
    //       dt.toLocaleString("hu-HU", { timeZone: "Europe/Budapest" })
    //     );
    //     this.time_stop = dt.toLocaleString("hu-HU", {
    //       timeZone: "Europe/Budapest",
    //     });
    //   }
    // },
    time_start_show() {
      if (
        this.time_start_show &&
        this.time_start_show.length > 4 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(this.currentDate, this.time_start_show, "start");
      }
    },
    time_stop_show() {
      if (
        this.time_stop_show &&
        this.time_stop_show.length > 4 &&
        this.timeSync
      ) {
        //Get synced time
        this.syncDateTime(this.currentDate, this.time_stop_show, "stop");
      }
    },
    time_start() {
      if (this.time_start && this.time_start.length > 1 && this.timeSync) {
        //Get synced time
        this.syncDateTime(
          this.currentDate,
          this.time_start,
          "start",
          "chooser"
        );
      }
    },
    time_stop() {
      if (this.time_stop && this.time_stop.length > 1 && this.timeSync) {
        //Get synced time
        this.syncDateTime(this.currentDate, this.time_stop, "stop", "chooser");
      }
    },
  },
  methods: {
    getAvailableDays() {
      axios
        .post(this.$store.getters.getApiUrl + "workday/availableDays", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Available days - resp", response.data);
            this.availableDays = response.data.availableDays;
            this.currentDate = response.data.currentDate;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    saveWorkdays() {
      if (Object.keys(this.checkedDays) < 1) {
        //Display - error
        this.$toasted.show("Legalább 1 munkanap megadása kötelező!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
          icon: "error_outline",
        });
      } else {
        //Send Services data to API & log in to dashboard
        axios
          .post(this.$store.getters.getApiUrl + "workday/massCreateOrUpdate", {
            token: this.$store.getters.getToken,
            checkedDays: this.checkedDays,
            time_start: this.time_start,
            time_stop: this.time_stop,
          })
          .then((response) => {
            if (response.status === 200) {
              this.$toasted.show("Sikeres mentés!", {
                position: "bottom-center",
                duration: 3500,
                fullWidth: true,
                fitToScreen: true,
                type: "success",
                // icon: "error_outline"
              });

              //Reset values
              this.checkedDays = {};
              this.showDateModal = false;
              this.getAvailableDays();

              //Redirect back to the calendar
              // this.$router.push({ name: "ServiceProviderCalendar" });
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    openDateTime(element) {
      if (element == "start") {
        this.$refs.dateTimeStart.isOpen = true;
      }

      if (element == "stop") {
        this.$refs.dateTimeStop.isOpen = true;
      }
    },
    syncDateTime(date = null, time = null, type = "start", from = null) {
      this.timeSync = false;
      axios
        .post(this.$store.getters.getApiUrl + "time/sync", {
          token: this.$store.getters.getToken,
          date: date,
          time: time,
          from: from,
        })
        .then((response) => {
          if (response.status === 200) {
            //FIXME: disable console.log
            // console.log("------------");
            // console.log("Time sync APi resp: ", response.data);

            if (type == "start") {
              this.time_start = response.data.time.dateTime;
              this.time_start_show = response.data.time.time;
            }

            if (type == "stop") {
              this.time_stop = response.data.time.dateTime;
              this.time_stop_show = response.data.time.time;
            }

            //FIXME: disable console.log
            // console.log("Time synced: ", this.time_start, this.time_stop);
            // console.log("------------");

            setTimeout(() => {
              this.timeSync = true;
            }, 250);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>
