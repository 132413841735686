<template>
  <div class="section full">
    <ul v-if="shops" class="listview link-listview">
      <li v-for="(sp, index) in shops" :key="index">
        <a
          href="javascript:void(0)"
          @click="
            $router.push({ name: 'SearchShopServices', params: { id: sp.id } })
          "
        >
          <div>
            <small>
              <strong>
                {{ sp.name }}
              </strong>
              <br />
              {{ sp.zip }} {{ sp.city }}, {{ sp.address }}
            </small>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "List",
  data: () => ({}),
  components: {},
  computed: {},
  props: {
    shops: Array
  },
  methods: {},
  mounted() {}
};
</script>
