<template>
  <div>
    <Auth />

    <Header
      title="Kapcsolatok"
      muted=""
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        @click="newContact"
        class="fab"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <ion-icon
          name="add-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
      </a>
    </div>

    <div class="section full">
      <!--Contacts list-->
      <ul v-if="contacts && contacts.length > 0" class="listview link-listview">
        <li v-for="(contact, index) in contacts" :key="index">
          <a href="javascript:void(0)" @click="showContactDetails(contact)">
            <div>
              {{ contact.name }}
            </div>
            <div v-if="contact.contact_user_id !== null">
              <span v-if="contact.accepted === 1" class="badge badge-success">
                KAPCSOLATBAN
              </span>
              <span v-else class="badge badge-warning"> FÜGGŐBEN </span>
            </div>
            <span v-else class="badge badge-info">SAJÁT KAPCSOLAT</span>
          </a>
        </li>
      </ul>

      <div v-else class="section full">
        <ErrorPanel
          class-label="alert alert-primary mainGreen"
          title="Hoppá!"
          message="Nincsenek kapcsolataid!"
        />
      </div>
    </div>

    <!--Modal to show & edit contact-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="contactModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton2"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {{ selectedContact.name }} <br />
                <small>adatai</small>
              </h5>
            </div>

            <!--Client version-->
            <div class="modal-body">
              <div class="mb-2">
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="label ml-1" for="name">Név</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="Név"
                      v-model="form.name"
                      :class="{ 'is-invalid': errors.name }"
                      :disabled="{ true: form.editable }"
                    />
                    <div class="invalid-feedback" v-if="errors.name">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="label ml-1" for="phone">Telefonszám</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Telefonszám"
                      v-model="form.phone"
                      :class="{ 'is-invalid': errors.phone }"
                      :disabled="{ true: form.editable }"
                    />
                    <div class="invalid-feedback" v-if="errors.phone">
                      {{ errors.phone[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="label ml-1" for="email">Email cím</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Email cím"
                      v-model="form.email"
                      :class="{ 'is-invalid': errors.email }"
                      :disabled="{ true: form.editable }"
                    />
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    v-if="selectedContact.contact_user_id !== null"
                    class="badge badge-pill mt-3 p-2 badge-primary"
                    :class="{ 'badge-success': selectedContact.accepted === 1 }"
                  >
                    <span
                      v-if="selectedContact.accepted === 1"
                      style="font-size: 1rem"
                    >
                      Kapcsolatban
                    </span>
                    <span v-else style="font-size: 1rem"> Függőben </span>
                  </div>
                  <div v-else class="badge badge-pill mt-3 p-2 badge-info">
                    <span style="font-size: 1rem"> Saját kapcsolat </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                  v-if="
                    selectedContact.accepted === 0 &&
                    selectedContact.contact_user_id === user.id
                  "
                  href="javascript:void(0)"
                  @click="affirmativeContact"
                  class="btn btn-text-primary btn-block"
                >
                  MEGERŐSÍTÉS
                </a>
                <a
                  href="javascript:void(0)"
                  @click="updateContact"
                  class="btn btn-text-success btn-block"
                >
                  MENTÉS
                </a>
                <a
                  href="javascript:void(0)"
                  @click="deleteContact"
                  class="btn btn-text-danger btn-block"
                >
                  TÖRLÉS
                </a>
                <a
                  href="javascript:void(0)"
                  @click="contactModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                >
                  MÉGSE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!--Modal to create new contact-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="contactNewModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton3"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Új kapcsolat <br />
                <small>adatai</small>
              </h5>
            </div>

            <!--Client version-->
            <div class="modal-body">
              <div class="mb-2">
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="label ml-1" for="name">Név</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="Név"
                      v-model="form.name"
                      :class="{ 'is-invalid': errors.name }"
                    />
                    <div class="invalid-feedback" v-if="errors.name">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="label ml-1" for="phone">Telefonszám</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Telefonszám"
                      v-model="form.phone"
                      :class="{ 'is-invalid': errors.phone }"
                    />
                    <div class="invalid-feedback" v-if="errors.phone">
                      {{ errors.phone[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="label ml-1" for="email">Email cím</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Email cím"
                      v-model="form.email"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                  href="javascript:void(0)"
                  @click="saveNewContact"
                  class="btn btn-text-success btn-block"
                >
                  MENTÉS
                </a>
                <a
                  href="javascript:void(0)"
                  @click="contactNewModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                >
                  MÉGSE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="contactModal || contactNewModal"
      class="modal-backdrop fade show"
    ></div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Contacts",
  components: {
    ErrorPanel,
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    contacts: {},
    contactModal: false,
    contactNewModal: false,
    selectedContact: {},
    form: {
      name: "",
      email: "",
      phone: "",
      editable: false,
    },
  }),
  methods: {
    getContacts() {
      axios
        .post(this.$store.getters.getApiUrl + "contact/list/contactMenu", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    showContactDetails(contact) {
      this.selectedContact = contact;
      this.contactModal = true;
      this.form.name = this.selectedContact.name;
      this.form.phone = this.selectedContact.phone;
      this.form.email = this.selectedContact.email;
      this.form.editable = this.selectedContact.editable;
    },
    deleteContact() {
      //Send id to delete notification
      axios
        .post(this.$store.getters.getApiUrl + "contact/delete", {
          token: this.$store.getters.getToken,
          id: this.selectedContact.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
            this.selectedContact = "";
            this.contactModal = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    updateContact() {
      //Send id to confirm appointment
      axios
        .post(this.$store.getters.getApiUrl + "contact/update", {
          token: this.$store.getters.getToken,
          id: this.selectedContact.id,
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
            this.selectedContact = "";
            this.contactModal = false;

            //Clear form values
            this.form.name = "";
            this.form.email = "";
            this.form.phone = "";

            //Toaster from success confirmation
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    affirmativeContact() {
      //Send id to confirm appointment
      axios
        .post(this.$store.getters.getApiUrl + "contact/affirmative", {
          token: this.$store.getters.getToken,
          id: this.selectedContact.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
            this.selectedContact = "";
            this.contactModal = false;

            //Clear form values
            this.form.name = "";
            this.form.email = "";
            this.form.phone = "";

            //Toaster from success confirmation
            this.$toasted.show("Sikeres megerősítés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    newContact() {
      //Clear form values
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";

      //Show modal
      this.contactNewModal = true;
    },
    saveNewContact() {
      axios
        .post(this.$store.getters.getApiUrl + "contact/store", {
          token: this.$store.getters.getToken,
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
        })
        .then((response) => {
          if (response.status === 200) {
            this.contacts = response.data.contacts;
            this.selectedContact = "";
            this.contactNewModal = false;

            //Clear form values
            this.form.name = "";
            this.form.email = "";
            this.form.phone = "";

            //Toaster from success confirmation
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getContacts();
  },
};
</script>
