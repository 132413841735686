<template>
  <div>
    <!--<Auth />-->

    <Header
      title="Foglalások"
      muted=""
      back-route-name="Dashboard"
      back-route-param="id"
      custom-class="bg-active text-light"
    />

    <div class="section full mt-2 text-center">
      <div class="row text-center p-1">
        <div class="col-12 p-1">
          <vc-calendar
            trim-weeks
            is-expanded
            :locale="localeSettings"
            :attributes="attributes"
            @update:to-page="updateCalendar"
            @dayclick="dayClicked"
          ></vc-calendar>
        </div>
      </div>
    </div>

    <div class="section full">
      <!--Appointments list-->
      <ul
        v-if="appointments && appointments.length > 0"
        class="listview link-listview"
      >
        <li v-for="(appointment, index) in appointments" :key="index">
          <a
            href="javascript:void(0)"
            @click="showAppointmentDetails(appointment)"
          >
            <div>
              <small>
                <span v-if="appointment.user.shop">{{
                  appointment.user.shop.name
                }}</span>
                <br />
                <strong v-if="appointment.user">{{
                  appointment.user.name
                }}</strong>
                <strong v-else>{{ appointment.contact.name }}</strong>
              </small>
              <br />
              <small>{{ appointment.service.title }}</small> <br />
              {{ appointment.time_start }}
            </div>
            <span
              v-if="appointment.is_available === 1"
              class="badge badge-success"
              >ELÉRHETŐ</span
            >
            <span
              v-else-if="appointment.is_available === 2"
              class="badge badge-warning"
              >JÓVÁHAGYÁSRA VÁR</span
            >
            <span
              v-else-if="appointment.is_available === 3"
              class="badge badge-success"
              >MEGERŐSÍTVE</span
            >
            <span
              v-else-if="appointment.is_available === 4"
              class="badge badge-warning"
              >TÖRLÉS JÓVÁHAGYÁSRA VÁR</span
            >
          </a>
        </li>
      </ul>

      <div v-else class="section full">
        <div class="wide-block-transparent pt-2 pb-2">
          <div class="alert alert-primary mainGreen" role="alert">
            <h4 class="alert-title">Hoppá!</h4>
            Nincsenek elérhető időpontok! <br />
            Kérlek nézz meg egy másik napot!
          </div>
        </div>
      </div>
    </div>

    <!--Modal to show an appointment-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="detailsModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton2"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Időpont részletei</h5>
            </div>

            <div class="modal-body">
              <div v-if="detailedAppointment.user" class="mb-2">
                <h5>Szolgáltatói adatok</h5>
                {{ detailedAppointment.user.shop.name }} <br />
                <strong> {{ detailedAppointment.user.name }} </strong> <br />
                <strong> {{ detailedAppointment.user.phone }} </strong> <br />
                <strong> {{ detailedAppointment.user.email }} </strong> <br />
                <strong v-if="detailedAppointment.user.shop">
                  {{ detailedAppointment.user.shop.zip }}
                  {{ detailedAppointment.user.shop.city }}, <br />
                  {{ detailedAppointment.user.shop.address }}
                </strong>
                <br />
              </div>

              <div class="">
                <h5>Időpont</h5>
                <strong>{{ detailedAppointment.date }}</strong> <br />
                <strong> {{ detailedAppointment.time_start }} </strong> -
                <strong> {{ detailedAppointment.time_stop }} </strong>
              </div>

              <div
                v-if="
                  detailedAppointment.reminder_time &&
                  detailedAppointment.reminder_unit
                "
                class="mt-2"
              >
                <h5>Emlékeztető ennyivel előbb</h5>
                <strong>{{ detailedAppointment.reminder_time }}</strong>
                <strong> {{ detailedAppointment.reminder_unit }} </strong>
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                  v-if="detailedAppointment.is_available !== 4"
                  href="javascript:void(0)"
                  @click="
                    deleteModal = true;
                    detailsModal = false;
                  "
                  class="btn btn-text-danger btn-block"
                  data-dismiss="modal"
                  >LEMOND</a
                >
                <a
                  v-if="detailedAppointment.state === 4"
                  href="javascript:void(0)"
                  class="btn btn-text-danger btn-block"
                  data-dismiss="modal"
                  >TÖRLÉS JÓVÁHAGYÁSRA VÁR</a
                >
                <a
                  href="javascript:void(0)"
                  @click="detailsModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                  >MÉGSE</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <!--Modal to send deletion message to service provider-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="deleteModal"
        class="modal fade dialogbox show"
        id="DialogForm"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Miért szeretnéd lemondani a foglalásod?
              </h5>
            </div>
            <div class="modal-body text-left mb-2">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="message">Üzenet</label>
                  <input
                    type="text"
                    id="message"
                    class="form-control"
                    placeholder="Mit szeretnél üzenni?"
                    v-model="message"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="btn-inline">
                <button
                  type="button"
                  class="btn btn-text-secondary"
                  data-dismiss="modal"
                  @click="
                    deleteModal = false;
                    detailsModal = true;
                  "
                >
                  MÉGSE
                </button>
                <button
                  type="button"
                  class="btn btn-text-danger"
                  data-dismiss="modal"
                  @click="sendDeleteMessage"
                >
                  KÜLDÉS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Calendar",
  components: {
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    appointments: {},
    detailsModal: false,
    detailedAppointment: {},
    attributes: {
      key: "today",
      highlight: true,
      dates: new Date(),
    },
    localeSettings: {
      id: "hu",
      firstDayOfWeek: 2,
      masks: {
        weekdays: "WW",
      },
    },
    deleteModal: false,
    message: "",
    currentDate: "",
  }),
  methods: {
    getAppointmentsForCalendar() {
      if (this.loggedIn) {
        axios
          .post(this.$store.getters.getApiUrl + "calendar", {
            token: this.$store.getters.getToken,
          })
          .then((response) => {
            if (response.status === 200) {
              this.attributes = response.data.attributes;
              this.appointments = response.data.appointments;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    dayClicked(day) {
      if (this.loggedIn) {
        axios
          .post(this.$store.getters.getApiUrl + "calendar/getByDay", {
            token: this.$store.getters.getToken,
            day: day.id,
          })
          .then((response) => {
            if (response.status === 200) {
              this.attributes = response.data.attributes;
              this.appointments = response.data.appointments;
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    showAppointmentDetails(appointment) {
      this.detailedAppointment = appointment;
      this.detailsModal = true;
    },
    confirmateAppointment() {
      axios
        .post(
          this.$store.getters.getApiUrl + "calendar/appointment/confirmation",
          {
            token: this.$store.getters.getToken,
            id: this.detailedAppointment.id,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.appointments = response.data.appointments;
            this.detailsModal = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    sendDeleteMessage() {
      if (!this.message) {
        this.$toasted.show("Üzenet megadása kötelező!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: false,
          type: "error",
          // icon: "checkmark-outline"
        });
      } else {
        axios
          .post(
            this.$store.getters.getApiUrl +
              "conversation/message/sendDeleteMessageFromCalendar",
            {
              token: this.$store.getters.getToken,
              id: this.detailedAppointment.id,
              message: this.message,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              //Close modal & reset message value
              this.deleteModal = false;
              this.message = "";
              this.detailedAppointment = "";
              this.appointments = response.appointments;

              //Show a toaster with success
              this.$toasted.show("Sikeres üzenetküldés!", {
                position: "bottom-center",
                duration: 2500,
                fullWidth: true,
                fitToScreen: false,
                type: "success",
                // icon: "checkmark-outline"
              });
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    updateCalendar(calendar) {
      axios
        .post(this.$store.getters.getApiUrl + "calendar/getByMonth", {
          token: this.$store.getters.getToken,
          month: calendar.month,
          year: calendar.year,
          day: this.currentDate,
        })
        .then((response) => {
          //Init attributes to null
          this.attributes = {
            key: "today",
            highlight: true,
            dates: new Date(),
          };

          //When the response is 200, we set the calendar attributes
          if (response.status === 200) {
            this.attributes = response.data.attributes;
          }
        })
        .catch((error) => {
          if (error.response.length > 0) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
  },
  mounted() {
    this.getAppointmentsForCalendar();
  },
};
</script>
