<template>
  <div>
    <Auth />

    <Header
      title="Munkanap törlés"
      back-route-name="MassWorkday"
      custom-class="bg-active text-light"
    />

    <div class="section mb-2 mt-2 full">
      <div class="wide-block">
        <div class="section inset my-2">
          <div class="row">
            <div class="col-12 my-2">
              <span class="blockTitle">Hónap megadás</span>
            </div>
            <div
              v-for="(month, index) in months"
              :key="'wm-' + index"
              class="col-4"
            >
              <div
                class="monthButton"
                :class="{
                  activeMonth: checkedSelectedMonth(index),
                  disabledMonth: index <= currentMonth - 1,
                }"
                @click="selectMonth(index)"
              >
                {{ month }}
              </div>
            </div>
          </div>
          <div v-if="!showLoader && checkedMonths.length > 0" class="row">
            <div class="col-12 my-2">
              <span class="blockTitle">Munkanap kiválasztása</span>
            </div>
            <div class="col-12">
              <div class="workdayListContainer">
                <div v-if="days.length > 0" class="workdayList">
                  <div class="input-list">
                    <div
                      v-for="(day, index) in days"
                      :key="'wb_' + index"
                      class="custom-control custom-checkbox"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="'day-' + index"
                        v-model="checkedDays[day.id]"
                        @click="clickCheckedDay(day.id)"
                      />
                      <label class="custom-control-label" :for="'day-' + index">
                        {{ day.date }}
                        <span
                          v-show="day.has_appointment"
                          class="badge badge-active"
                          >VAN FOGLALÁS</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <div v-else class="workdayList">
                  <ErrorPanel
                    class-label="alert alert-primary mainGreen"
                    title="Nem található munkanap!"
                    message="A megadott hónap(ok)ban"
                    message2=" nem található munkanap."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="showLoader">
            <div class="col-12">
              <div class="loaderContainer">
                <lottie-animation
                  ref="anim"
                  :animationData="require('@/assets/img/lottie/loader4.json')"
                  :loop="true"
                />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div
              class="btn btn-lg btn-block mb-1"
              :class="
                selectedDays.length !== 0 && checkedMonths.length !== 0
                  ? 'btn-primary'
                  : 'btn-disabled'
              "
              @click="deleteWorkdays"
            >
              <div v-if="showButtonLoader">
                <lottie-animation
                  ref="anim"
                  :animationData="require('@/assets/img/lottie/loader3.json')"
                  :loop="true"
                />
              </div>
              <div v-else>
                {{ buttonTitle }}
              </div>
            </div>
            <div
              class="btn btn-lg btn-primary btn-block mb-1"
              @click="resetData"
              v-show="showResetButton"
            >
              <div>
                <ion-icon
                  name="refresh-outline"
                  role="img"
                  class="md hydrated"
                ></ion-icon>
              </div>
              <div>Visszaállít</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import LottieAnimation from "lottie-web-vue";
import Auth from "../../components/Auth";
import ErrorPanel from "@/partials/ErrorPanel";
import Header from "../../partials/Header";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MassWorkdayDelete",
  data: () => ({
    months: {},
    days: {},
    currentMonth: 0,
    checkedMonths: [],
    checkedDays: {},
    selectedDays: [],
    currentDate: "",
    errors: {},
    showLoader: false,
    showButtonLoader: false,
    showMessageModal: false,
    message: "",
    showResetButton: false,
    buttonTitle: "Törlés",
  }),
  components: {
    Auth,
    ErrorPanel,
    Header,
    LottieAnimation,
  },
  updated() {},
  computed: { ...mapGetters(["user"]) },
  watch: {},
  methods: {
    checkedSelectedMonth(index) {
      return this.checkedMonths.indexOf(index) !== -1 ? true : false;
    },
    clickCheckedDay(id) {
      //Add selected day's index to array
      if (this.selectedDays.indexOf(id) === -1) {
        this.selectedDays.push(id);
      } else {
        this.selectedDays.splice(this.selectedDays.indexOf(id), 1);
      }

      //If user start again the deletion, reset buttonTitle
      if (this.buttonTitle == "Törölve") {
        this.buttonTitle = "Törlés";
      }
    },
    deleteWorkdays() {
      this.showButtonLoader = true;
      axios
        .post(this.$store.getters.getApiUrl + "workday/delete", {
          token: this.$store.getters.getToken,
          months: this.checkedMonths,
          days: this.selectedDays,
        })
        .then((response) => {
          if (response.status === 200) {
            //Hide loader
            setTimeout(() => {
              this.buttonTitle = "Törölve";
              this.days = response.data.days;
              this.selectedDays = [];
              this.showButtonLoader = false;
              this.showResetButton = true;
            }, 1500);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    async getDaysInMonth() {
      //Send Services data to API & log in to dashboard
      await axios
        .post(this.$store.getters.getApiUrl + "workday/workdaysInMonth", {
          token: this.$store.getters.getToken,
          months: this.checkedMonths,
        })
        .then((response) => {
          if (response.status === 200) {
            //Get days from response
            this.days = response.data.days;

            //Hide loader
            setTimeout(() => {
              this.showLoader = false;
            }, 500);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    async getMonths() {
      await axios
        .post(this.$store.getters.getApiUrl + "workday/days-months", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.months = response.data.months;
            this.currentDate = response.data.currentDate;
            this.currentMonth = response.data.currentMonth;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    selectMonth(index) {
      //If currentMonth is less than index, then return null
      if (index < this.currentMonth) return;

      //If index not in array we add it else remove it
      if (this.checkedMonths.indexOf(index) === -1) {
        //Add index to array
        this.checkedMonths.push(index);
        //Get days
        this.getDaysInMonth();
        this.showLoader = true;
      } else {
        //Remove index from array
        this.checkedMonths.splice(this.checkedMonths.indexOf(index), 1);
        //If checkedMonths is not empty, then get days
        if (this.checkedMonths.length > 0) {
          this.showLoader = true;
          this.getDaysInMonth();
        }
      }
    },
    resetData() {
      this.checkedDays = {};
      this.selectedDays = [];
      this.checkedMonths = [];
      this.showResetButton = false;
      this.showMessageModal = false;
      this.buttonTitle = "Törlés";
    },
  },
  mounted() {
    //Get available days
    this.getMonths();
  },
};
</script>
