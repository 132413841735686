<template>
  <div>
    <RegistrationHeaderCustomer />

    <div class="section mb-2 mt-2 full">
      <div class="wide-block pt-2 pb-2">
        <form ref="form">
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                id="name1"
                name="name"
                placeholder="Név"
                v-model="registration.name"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback" v-if="errors.name">
                {{ errors.name[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="email"
                class="form-control"
                id="email1"
                name="email"
                placeholder="Email cím"
                v-model="registration.email"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback" v-if="errors.email">
                {{ errors.email[0] }}
              </div>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                id="password1"
                name="password"
                placeholder="Jelszó"
                v-model="registration.password"
                :class="{ 'is-invalid': errors.password }"
              />
              <div class="invalid-feedback" v-if="errors.password">
                {{ errors.password[0] }}
              </div>
            </div>
          </div>

          <div class="form-button-group">
            <div @click="checkForm" class="btn btn-magenta btn-lg btn-block">
              Tovább
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RegistrationHeaderCustomer from "@/partials/registration/RegistrationHeaderCustomer";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "CustomerStep1",
  components: {
    RegistrationHeaderCustomer,
  },
  computed: {},
  data: () => ({
    errors: {},
    apiURL: "",
    registration: {
      name: "",
      password: "",
      email: "",
    },
  }),
  methods: {
    checkForm() {
      this.errors = "";

      axios
        .post(this.$store.getters.getApiUrl + "registration/customer_step1", {
          email: this.$data.registration.email,
          name: this.$data.registration.name,
          password: this.$data.registration.password,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("updateCustomerRegistrationStep1", {
              registration: this.$data.registration,
            });

            this.$router.push({ name: "CustomerStep2" });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.$store.state.registration.step = 1;
    this.$data.registration = this.$store.getters.getRegistrationData;

    //Add bg class to body
    document.body.classList.add("registrationBgCustomer");
  },
  destroyed() {
    //Remove bg class to body
    document.body.classList.remove("registrationBgCustomer");
  },
};
</script>
