<template>
  <div>
    <!--<Auth />-->

    <Header
      title="GYIK"
      muted=""
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <div class="section full mt-2 mb-2">
      <div class="section-title">Gyakran Ismételt Kérdések</div>

      <div class="accordion" id="accordionExample3">
        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion1 = !accordion1"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              Regisztráció
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion1 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
              <p>
                E-mail címmel lehetséges regisztrálni vendég és szolgáltatóként
                egyaránt
              </p>
              <p>
                <strong>Vendégként:</strong>
                    <ul>
                        <li>név megadása </li>
                        <li>e-mail cím megadása </li>
                        <li>jelszó megadása</li>
                    </ul>
                    Ezek után személyes adatainkra is szükség van:
                    <ul>
                       <li>telefonszám</li>
                       <li>irányítószám, város, utca, házszám</li>
                    </ul>
                 ha ezekkel megvagyunk,regisztráltunk is, indulhat az app!
              </p>
              <p>
                <strong>Szolgáltatóként: </strong>
                <ul>
                   <li>Név megadása </li>
                   <li>e-mail cím megadása</li>
                   <li>jelszó megadása</li>
                </ul>
                Ezek után személyes adatainkra is szükség van:
                <ul>
                    <li>telefonszám</li>
                    <li>irányítószám, város, utca, házszám</li>
                    <li>kategória kiválasztása a vállalkozásunknak </li>
                    <li>szolgáltatás megnevezése
                        <br>
                        <small>
                            Ebben a menüpontban
                            megadhatjuk a megnevezést, szolgáltatás ára, mennyi időt vesz
                            igénybe, napi limitet is beállíthatunk és rövid leírás a
                            szolgáltatásról.
                        </small>
                    </li>
                </ul>
              </p>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion2 = !accordion2"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              Belépés
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion2 }"
            style=""
          >
            <div class="accordion-content">
              Az alkalmazás használatához a regisztáció alkalmával megadott e-mail cím és jelszó szükséges.
            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion3 = !accordion3"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              GDPR
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion3 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
              A GDPR a következő linken érhető el:
              <a href="https://fixeet.com/gdpr.pdf">GDPR Link</a>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion4 = !accordion4"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              Szolgáltatás feladása
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion4 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
              <p>Korlátlan mennyiségű szolgáltatást rögzíthetünk vállalkozásunknak.</p>
              <p>
                  <strong>Szolgáltatásaim menüpont</strong>
                  <br>
                  <span>
                      Ebben a menüpontban megadhatjuk a megnevezést, szolgáltatás ára,
                      mennyi időt vesz igénybe,
                      napi limitet is beállíthatunk és rövid leírás a szolgáltatásról.
                      Naptár láthatósága privát és publik nézet közül választhatunk.
                      Privát nézetnél csak az ismerősök látják a szabad időpontjainkat és munkanapokat.
                  </span>
              </p>

            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion5 = !accordion5"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
            Időpont foglalás
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion5 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
                <ul>
                    <li>Időpontodat, gyorsan egyszerűen lefoglalhatod szolgáltatódnál a kiválasztott szolgáltatásra</li>
                    <li>Ha szolgáltatód privát naptárral rendelkezik, lépjetek kapcsolatba az időpontfoglaláshoz</li>
                    <li>
                        Kiválasztod a szolgáltatódat, ezt követően melyik szolgáltatását szeretnéd igénybe venni, azután a napot. <br>
                        Ezután minden szabadidőpontot látsz a kiválasztott napon. <br>
                        Egy kattintással lefoglalható. <br>
                        Szolgáltatód miután jóváhagyta az időpontot értesítést kapsz.
                    </li>
                </ul>

            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion6 = !accordion6"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              Időpont lemondás
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion6 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
              <p>
                  <strong>Vendégként</strong> <br>
                  <span>
                      Időpontod lemondása esetén a törlés után egy rövid üzenettel tudod időpontod törölni.
                  </span>

              </p>
              <p>
                  <strong>Szolgáltatóként</strong>
                  <span>
                      Időpontod lemondása esetén a törlés után a vendéged értesítést kap róla.
                  </span>
              </p>

            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion7 = !accordion7"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              QR kód
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion7 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
              <p>
                  A QR kód szolgáltatás jelenleg nem elérhető. <br>
                  Dolgozunk rajta 🙂
              </p>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion8 = !accordion8"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              Fiók törlése
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion8 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
                <p>
                    A fiók törlése a <strong>beállítások</strong> menüpontban érhető el. <br>
                    <br>
                    <strong>
                        ❗️FONTOS❗️ <br>
                        <br>
                        <span>
                            Törlés után a fiók és a benne tárolt szolgáltatások, időpontok, kapcsolati adatok mind elvesznek!
                            <br>
                            Ezeket az adatokat visszaállítani <strong>NEM TUDJUK</strong>!
                        </span>
                    </strong>
                </p>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="accordion-header">
            <button
              class="btn collapsed"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              @click="accordion9 = !accordion9"
            >
              <ion-icon
                name="help-circle-outline"
                role="img"
                class="md hydrated"
                aria-label="help circle outline"
              ></ion-icon>
              Kapcsolat
            </button>
          </div>
          <div
            class="accordion-body collapse"
            :class="{ show: accordion9 }"
            data-parent="#accordionExample3"
            style=""
          >
            <div class="accordion-content">
                    Lépj velünk kapcsolatba az alábbi email címen: <br>
                    <a href="mailto:hello@fixeet.com">hello@fixeet.com</a>
                    <br>
                    vagy az alábbi közösségi médiák egyikén
                    <br>
                    <div class="row mt-2">
                        <div class="col-10 offset-1 mb-2">
                            <a href="https://www.facebook.com/Fixeet-102974954959788" class="btn btn-icon btn-sm">
                                <img
                                    :src="require('@/assets/img/facebook-logo.png')"
                                    class="hydrated md img-fluid mr-1"
                                    alt="messenger"
                                />
                                Facebook
                            </a>
                        </div>
                        <div class="col-10 offset-1 mb-2">
                            <a href="http://m.me/102974954959788" class="btn btn-icon btn-sm">
                                <img
                                    :src="require('@/assets/img/messenger_logo.png')"
                                    class="hydrated md img-fluid mr-1"
                                    alt="messenger"
                                />
                                Messenger
                            </a>
                        </div>
                        <div class="col-10 offset-1">
                            <a href="instagram://user?username=fixeetapp" class="btn btn-icon btn-sm">
                                <img
                                    :src="require('@/assets/img/instagram-logo.png')"
                                    class="hydrated md img-fluid mr-1"
                                    alt="messenger"
                                />
                                Instagram
                            </a>
                        </div>
                    </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Notification",
  components: {
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    accordion1: false,
    accordion2: false,
    accordion3: false,
    accordion4: false,
    accordion5: false,
    accordion6: false,
    accordion7: false,
    accordion8: false,
    accordion9: false,
  }),
  methods: {},
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {},
};
</script>
