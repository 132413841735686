<template>
  <div>
    <Auth />

    <Header
      title="Csoportos munkanap megadás"
      back-route-name="ServiceProviderCalendar"
      custom-class="bg-active text-light"
    />

    <div class="section mb-2 mt-2 full">
      <!-- <div class="section-title pt-2">Munkanapok</div> -->
      <div class="wide-block customForm">
        <form
          id="form1"
          action="#"
          enctype="multipart/form-data"
          method="post"
          class=""
        >
          <div
            class="form-button-group"
            style="position: relative; justify-items: center"
          >
            <div>
              <div
                @click="$router.push({ name: 'MassWorkdayRepeatly' })"
                class="btn btn-success btn-lg btn-block mt-1"
              >
                Ismétlődő munkanapok
              </div>
              <div
                @click="$router.push({ name: 'MassWorkdayFix' })"
                class="btn btn-success btn-lg btn-block mt-1"
              >
                Egyedi munkanap
              </div>
              <div
                @click="$router.push({ name: 'MassWorkdayDelete' })"
                class="btn btn-primary btn-lg btn-block mb-1"
              >
                Munkanap törlés
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import Header from "../../partials/Header";
import Auth from "../../components/Auth";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MassWorkday",
  data: () => ({}),
  components: {
    Header,
    Auth,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {},
};
</script>

<style>
.customForm {
  position: flex;
  justify-content: space-between;
}
</style>
