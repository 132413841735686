<template>
  <div>
    <Auth />

    <Header
      title="Üzenetek"
      muted="üzenetváltás"
      back-route-name="Messages"
      custom-class="bg-active text-light"
    />

    <div
      v-if="conversation && conversation.messages"
      class="section inset mt-2"
    >
      <div
        v-for="(message, index) in conversation.messages"
        :key="index"
        class="message-item mt-1"
        :class="{ user: message.user_id === user.id }"
      >
        <div class="content">
          <div v-if="message.user_id !== user.id" class="title">
            {{ message.user.name }}
          </div>
          <div class="bubble" v-html="message.message">
            <!--{{ message.message }}-->
          </div>
          <div class="footer">{{ message.created_at }}</div>
        </div>
      </div>
    </div>

    <div v-else class="section full mb-2">
      <ErrorPanel
        title="Hoppá!"
        class-label="alert alert-primary mainGreen"
        message="Jelenleg egy üzenet sincs a beszélgetésben"
      />
    </div>

    <div @click="setFocus" class="chatFooter">
      <!--<form>-->
      <div class="form-group boxed chatFooterModInput">
        <div class="input-wrapper">
          <input
            type="text"
            class="form-control"
            placeholder="Üzenet írása..."
            v-model="message"
            ref="messageInput"
            @click="setFocus"
          />
          <!-- <i class="clear-input">
            <ion-icon
              name="close-circle"
              role="img"
              class="md hydrated"
              aria-label="close circle"
            ></ion-icon>
          </i> -->
        </div>
      </div>
      <button
        type="button"
        class="btn btn-icon btn-active rounded messagesSendButton"
        @click="sendMessage"
      >
        <div class="icon-custom icon-send-white align-self-center"></div>
      </button>
      <!--</form>-->
    </div>

    <!--<Nav />-->
    <!--<NavFooter />-->
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
// import Nav from "../partials/Nav";
// import NavFooter from "../partials/NavFooter";

import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MessagesShow",
  components: {
    ErrorPanel,
    Auth,
    Header,
    // Nav,
    // NavFooter
  },
  data: () => ({
    conversation: {},
    user: {},
    message: "",
    timer: null,
  }),
  methods: {
    getConversationMessages() {
      axios
        .post(this.$store.getters.getApiUrl + "conversation/message/show", {
          token: this.$store.getters.getToken,
          id: this.$route.params.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.conversation = response.data.conversation;
            this.scrollToBottom();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    sendMessage() {
      if (!this.message) {
        this.$toasted.show("Üzenet megadása kötelező!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: false,
          type: "error",
          // icon: "checkmark-outline"
        });
      } else {
        axios
          .post(this.$store.getters.getApiUrl + "conversation/message/send", {
            token: this.$store.getters.getToken,
            id: this.$route.params.id,
            message: this.message,
          })
          .then((response) => {
            if (response.status === 200) {
              this.message = "";
              this.conversation = response.data.conversation;
              this.scrollToBottom();
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    scrollToBottom() {
      //Scroll to bottom of the container
      let el = this.$el.getElementsByClassName("section")[0];
      if (el) {
        setTimeout(function () {
          window.scrollBy(0, el.scrollHeight);
        }, 500);
      }
    },
    setFocus() {
      this.$refs.messageInput.focus();
      this.$refs.messageInput.scrollIntoView();
      this.$refs.messageInput.setSelectionRange(0, 9999);
    },
  },
  computed: {},
  mounted() {
    this.$refs.messageInput.focus();
    this.getConversationMessages();
    this.timer = setInterval(() => {
      this.getConversationMessages();
    }, 15000);
    this.user = this.$store.getters.getUserData;
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
