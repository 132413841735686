<template>
  <div>
    <!--<Auth />-->

    <Header
      :title="shop.name"
      :muted="service.name"
      back-route-name="SearchShopServices"
      back-route-param="id"
      :back-route-id="shop.id"
      custom-class="bg-active text-light"
    />

    <!--Send message button-->
    <!-- <div
      v-if="services && services.length > 1"
      class="fab-button animate bottom-right pb-5 mb-2"
    >
      <a
        href="javascript:void(0)"
        @click="messageModal = true"
        class="fab bg-active"
        aria-expanded="false"
      > -->
    <!--<ion-icon-->
    <!--  name="mail-outline"-->
    <!--  role="img"-->
    <!--  class="md hydrated"-->
    <!--  aria-label="add outline"-->
    <!--&gt;</ion-icon>-->
    <!-- <div class="icon-custom icon-mail-outline"></div>
      </a>
    </div> -->

    <div v-if="employees" class="section inset">
      <div
        class="wide-block bg-white bg-transparent-90 pt-3 pb-2 mt-1"
        v-for="(employee, index) in employees"
        :key="index"
        href="javascript:void(0)"
        @click="
          $router.push({
            name: 'Service',
            params: { employeeId: employee.id, serviceId: employee.serviceId },
          })
        "
      >
        <div class="content-header mb-05"></div>
        {{ employee.name }}

        <div
          class="d-inline-block text-primary text-right float-right"
          style="font-size: 2rem; margin-top: -1rem"
        >
          <!--<ion-icon name="chevron-forward-circle-outline"></ion-icon>-->
          <div class="icon-custom icon-right-arrow"></div>
        </div>

        <div class="content-footer mt-05 text-right text-black-100">
          Email: {{ employee.email }} <br />
          Telefonszám: {{ employee.phone }}
        </div>
      </div>
    </div>

    <div v-else class="section full">
      <ErrorPanel
        class-label="alert alert-primary mainGreen"
        title="Hoppá!"
        message="Nem találhatóak alkalmazottak!"
        message2="Kérlek nézz vissza kicsit később!"
      />
    </div>

    <!--Send message to shop Modal-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="messageModal"
        class="modal fade dialogbox show"
        id="DialogForm"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Üzenet küldés</h5>
            </div>
            <div class="modal-body text-left mb-2">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="message">Üzenet</label>
                  <input
                    type="text"
                    id="message"
                    class="form-control"
                    placeholder="Mit szeretnél üzenni?"
                    v-model="message"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="btn-inline">
                <button
                  type="button"
                  class="btn btn-text-secondary"
                  data-dismiss="modal"
                  @click="messageModal = false"
                >
                  MÉGSE
                </button>
                <button
                  type="button"
                  class="btn btn-text-primary"
                  data-dismiss="modal"
                  @click="sendMessage"
                >
                  KÜLDÉS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
// import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "SearchShopServicesEmployees",
  components: {
    ErrorPanel,
    // Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    service: {
      name: "",
    },
    employees: {},
    shop: {
      id: 1,
      name: "",
      services: {},
    },
    messageModal: false,
    message: "",
    user: {},
  }),
  methods: {
    getServices() {
      axios
        .post(this.$store.getters.getApiUrl + "search/shop/employees", {
          token: this.$store.getters.getToken,
          id: this.$route.params.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.shop = response.data.shop;
            this.service = response.data.service;
            this.employees = response.data.employees;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    sendMessage() {
      if (!this.message) {
        this.$toasted.show("Üzenet megadása kötelező!", {
          position: "bottom-center",
          duration: 2500,
          fullWidth: true,
          fitToScreen: false,
          type: "error",
          // icon: "checkmark-outline"
        });
      } else {
        axios
          .post(
            this.$store.getters.getApiUrl +
              "conversation/message/sendFromSearchByServiceProviderId",
            {
              token: this.$store.getters.getToken,
              id: this.serviceProvider.id,
              message: this.message,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              //Close modal & reset message value
              this.messageModal = false;
              this.message = "";

              //Show a toaster with success
              this.$toasted.show("Sikeres üzenetküldés!", {
                position: "bottom-center",
                duration: 2500,
                fullWidth: true,
                fitToScreen: false,
                type: "success",
                // icon: "checkmark-outline"
              });
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
  },
  mounted() {
    this.getServices();

    this.user = this.$store.getters.getUserData;

    // this.$store.commit("updateCurrentCategoryId", {
    //   currentCategoryId: this.$route.params.id
    // });

    //Add bg class to body
    document.body.classList.add("shopBg");
  },
  destroyed() {
    document.body.classList.remove("shopBg");
  },
};
</script>
