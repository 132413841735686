<template>
  <div>
    <Auth />

    <Header
      title="Értesítések"
      muted=""
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <div class="section full">
      <!--Appointments list-->
      <ul
        v-if="notifications && notifications.length > 0"
        class="listview link-listview"
      >
        <li v-for="(notification, index) in notifications" :key="index">
          <a
            href="javascript:void(0)"
            @click="showNotificationDetails(notification)"
          >
            <div v-if="notification.type == 0 && notification.appointment">
              <small
                v-if="
                  notification.appointment.deleted !== 1 &&
                  user.type !== 0 &&
                  notification.appointment.client
                "
              >
                <strong v-if="notification.appointment.client">
                  {{ notification.appointment.client.name }}
                </strong>
                <strong v-else-if="notification.appointment.contact">
                  {{ notification.appointment.contact.name }}
                </strong>
              </small>
              <small
                v-else-if="
                  notification.appointment.deleted != 1 && user.type == 0
                "
              >
                <strong v-if="notification.appointment.user">
                  {{ notification.appointment.user.name }}
                </strong>
                <strong v-else>
                  Törölt felhasználó
                </strong>
              </small>
              <small v-else>
                <span class="badge badge-pill badge-danger"> TÖRLÉS </span>
              </small>
              <br />
              <small v-if="notification.appointment.service">
                <strong>
                  {{ notification.appointment.service.title }}
                </strong>
              </small>
              <br />
              {{ notification.appointment.time_start }}
            </div>
            <div v-if="notification.type == 1 && notification.contact && notification.contact_id != 0">
              <small>
                <strong>
                  {{ notification.contact.name }}
                </strong>
              </small>
              <!-- <br /> -->
              <!-- <small v-if="notification.contact.email">
                <strong>
                  {{ notification.contact.email }}
                </strong>
              </small> -->
              <!-- <br />
              <small v-if="notification.contact.phone">
                <strong>
                  {{ notification.contact.phone }}
                </strong>
              </small> -->
              <br />
              <span class="badge badge-sm badge-info">
                Kapcsolati értesítés
              </span>
            </div>
            <span v-if="notification.state === 0" class="badge badge-active"
              >ÚJ</span
            >
          </a>
        </li>
      </ul>

      <div v-else class="section full">
        <ErrorPanel
          class-label="alert alert-primary mainGreen"
          title="Hoppá!"
          message="Nincsenek értesítések!"
        />
      </div>
    </div>

    <!--Modal to show an appointment-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="notificationModal"
        class="modal fade dialogbox show"
        id="DialogBlockButton2"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Értesítés részletei</h5>
            </div>

            <!--Client version-->
            <div
              v-if="selectedNotification.type == 0 && user.type === 0"
              class="modal-body"
            >
              <div
                v-if="
                  selectedNotification.appointment.user &&
                  selectedNotification.appointment.service
                "
                class="mb-2"
              >
                <h5 class="my-3">Állapot változás!</h5>
                <strong>
                  {{ selectedNotification.appointment.service.title }}
                </strong>
                <br />
                <strong>
                  {{ selectedNotification.appointment.user.name }}
                </strong>
                <br />

                <div v-if="selectedNotification.deleted !== 1">
                  <div
                    v-if="selectedNotification.appointment.is_available === 2"
                    class="badge badge-pill mt-3 p-2 badge-warning"
                  >
                    <span style="font-size: 1rem"> JÓVÁHAGYÁSRA VÁR </span>
                  </div>
                  <div
                    v-if="selectedNotification.appointment.is_available === 3"
                    class="badge badge-pill badge-success mt-3 p-2"
                  >
                    <span style="font-size: 1rem"> MEGERŐSÍTVE </span>
                  </div>
                  <div
                    v-if="selectedNotification.appointment.is_available === 4"
                    class="badge badge-pill badge-danger mt-3 p-2"
                  >
                    <span style="font-size: 1rem"> LEMONDVA </span>
                  </div>
                </div>
                <div v-else class="badge badge-pill badge-danger mt-3 p-2">
                  <span style="font-size: 1rem"> TÖRÖLVE </span>
                </div>
              </div>
            </div>

            <!-- SP version -->
            <div
              v-if="selectedNotification.type == 0 && user.type > 0"
              class="modal-body"
            >
              <div
                v-if="
                  selectedNotification.appointment.client &&
                  selectedNotification.appointment.service
                "
                class="mb-2"
              >
                <h5 class="my-3">Állapot változás!</h5>
                <strong>
                  {{ selectedNotification.appointment.service.title }}
                </strong>
                <br />
                <strong>
                  {{ selectedNotification.appointment.client.name }} <br />
                  {{ selectedNotification.appointment.client.phone }} <br />
                  {{ selectedNotification.appointment.client.email }}
                </strong>
                <br />

                <div
                  v-if="selectedNotification.appointment.is_available === 2"
                  class="badge badge-pill badge-info mt-3 p-2"
                >
                  <span style="font-size: 1rem"> JELENTKEZÉS </span>
                </div>

                <div
                  v-if="selectedNotification.appointment.is_available === 3"
                  class="badge badge-pill badge-success mt-3 p-2"
                >
                  <span style="font-size: 1rem"> MEGERŐSÍTVE </span>
                </div>
                <div v-if="selectedNotification.appointment.is_available === 4">
                  <div class="badge badge-pill badge-danger mt-3 p-2">
                    <span style="font-size: 0.9rem"> LEMONDVA </span>
                  </div>
                  <div class="badge badge-pill badge-warning mt-3 p-2">
                    <span style="font-size: 0.9rem">
                      TÖRLÉS MEGERŐSÍTÉSRE VÁR
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  selectedNotification.appointment.contact &&
                  selectedNotification.appointment.service
                "
                class="mb-2"
              >
                <h5 class="my-3">Állapot változás!</h5>
                <strong>
                  {{ selectedNotification.appointment.service.title }}
                </strong>
                <br />
                <strong>
                  {{ selectedNotification.appointment.contact.name }} <br />
                  {{ selectedNotification.appointment.contact.phone }} <br />
                  {{ selectedNotification.appointment.contact.email }}
                </strong>
                <br />

                <div
                  v-if="selectedNotification.appointment.is_available === 2"
                  class="badge badge-pill badge-info mt-3 p-2"
                >
                  <span style="font-size: 1rem"> JELENTKEZÉS </span>
                </div>

                <div
                  v-if="selectedNotification.appointment.is_available === 3"
                  class="badge badge-pill badge-success mt-3 p-2"
                >
                  <span style="font-size: 1rem"> MEGERŐSÍTVE </span>
                </div>
              </div>
            </div>

            <!-- Contact version -->
            <div v-if="selectedNotification.type == 1" class="modal-body">
              <div class="mb-2">
                <h5 class="my-3">Kapcsolati állapot változás!</h5>
                <strong>
                  {{ selectedNotification.contact.name }} <br />
                  {{ selectedNotification.contact.email }} <br />
                  {{ selectedNotification.contact.phone }}
                </strong>
                <br />
                <div
                  v-if="selectedNotification.contact.accepted == 0"
                  class="badge badge-pill badge-info mt-3 p-2"
                >
                  <span style="font-size: 1rem"> ISMERŐSNEK JELÖLÉS </span>
                </div>
                <div
                  v-if="selectedNotification.contact.accepted == 1"
                  class="badge badge-pill badge-info mt-3 p-2"
                >
                  <span style="font-size: 1rem"> ELFOGADVA </span>
                </div>

                <br />
              </div>
            </div>

            <div class="modal-footer">
              <div class="btn-list">
                <a
                  v-if="selectedNotification.appointment && selectedNotification.appointment.is_available === 2"
                  href="javascript:void(0)"
                  @click="confirmNotification()"
                  class="btn btn-text-success btn-block"
                >
                  MEGERŐSÍTÉS
                </a>
                <a
                  href="javascript:void(0)"
                  @click="deleteNotification()"
                  class="btn btn-text-danger btn-block"
                >
                  TÖRLÉS
                </a>
                <a
                  href="javascript:void(0)"
                  @click="notificationModal = false"
                  class="btn btn-text-secondary btn-block"
                  data-dismiss="modal"
                >
                  BEZÁR
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"></div>
      </div>
    </transition>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
import ErrorPanel from "@/partials/ErrorPanel";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Notification",
  components: {
    ErrorPanel,
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    errors: {},
    notifications: {},
    notificationModal: false,
    selectedNotification: {},
  }),
  methods: {
    getNotifications() {
      axios
        .post(this.$store.getters.getApiUrl + "notification", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.notifications = response.data.notifications;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    showNotificationDetails(notification) {
      this.selectedNotification = notification;
      this.notificationModal = true;

      //Send id to change notification state
      axios
        .post(this.$store.getters.getApiUrl + "notification/read", {
          token: this.$store.getters.getToken,
          id: this.selectedNotification.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.notifications = response.data.notifications;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    deleteNotification() {
      //Send id to delete notification
      axios
        .post(this.$store.getters.getApiUrl + "notification/delete", {
          token: this.$store.getters.getToken,
          id: this.selectedNotification.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.notifications = response.data.notifications;
            this.selectedNotification = "";
            this.notificationModal = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    confirmNotification() {
      //Send id to confirm appointment
      axios
        .post(this.$store.getters.getApiUrl + "notification/confirmate", {
          token: this.$store.getters.getToken,
          id: this.selectedNotification.id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.notifications = response.data.notifications;
            this.selectedNotification = "";
            this.notificationModal = false;

            //Toaster from success confirmation
            this.$toasted.show("Sikeres megerősítés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "success",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>
