<template>
  <div>
    <!--Notification panel-->
    <!--<transition-->
    <!--  name="custom-classes-transition"-->
    <!--  enter-active-class="animated slideInDown"-->
    <!--  leave-active-class="animated slideOutUp"-->
    <!--  :duration="750"-->
    <!--&gt;-->
    <!--  <div-->
    <!--    v-if="showNotification"-->
    <!--    id="notification-10"-->
    <!--    class="notification-box show"-->
    <!--    @click="forceCloseNotificationPanel = true"-->
    <!--  >-->
    <!--    <div class="notification-dialog ios-style">-->
    <!--      <div class="notification-header">-->
    <!--        <div class="in">-->
    <!--          <strong>Értesítés</strong>-->
    <!--        </div>-->
    <!--        <div class="right">-->
    <!--          <a-->
    <!--            href="javascript:void(0)"-->
    <!--            class="close-button"-->
    <!--            @click="forceCloseNotificationPanel = true"-->
    <!--          >-->
    <!--            <ion-icon-->
    <!--              name="close-circle"-->
    <!--              role="img"-->
    <!--              class="md hydrated"-->
    <!--              aria-label="close circle"-->
    <!--            ></ion-icon>-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="notification-content">-->
    <!--        <div class="in">-->
    <!--          <h3 class="subtitle">Új értesítéseket kapott!</h3>-->
    <!--        </div>-->
    <!--        <div class="icon-box text-danger">-->
    <!--          <ion-icon-->
    <!--            name="alert-circle-outline"-->
    <!--            role="img"-->
    <!--            class="md hydrated"-->
    <!--            aria-label="checkmark circle outline"-->
    <!--          ></ion-icon>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</transition>-->

    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInLeft"
      leave-active-class="animated slideOutLeft"
    >
      <div
        v-if="menuShow"
        class="modal fade panelbox panelbox-left"
        :class="{ show: menuShow, 'd-block': menuShow }"
        id="sidebarPanel"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body p-0">
              <!-- profile box -->
              <div class="profileBox">
                <div class="row w-100 text-center align-content-center">
                  <div class="col-12">
                    <div class="image-wrapper">
                      <div
                        v-if="user.profileImage"
                        :style="{
                          backgroundImage: 'url(' + user.profileImage + ')',
                        }"
                        class="profileImage"
                      ></div>
                      <div
                        v-else
                        :style="{
                          backgroundImage:
                            'url(' + require('@/assets/img/profile.jpg') + ')',
                        }"
                        class="profileImage"
                      ></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="in">
                      <strong>{{ user.name }}</strong>
                      <div class="text-muted">
                        {{ user.email }}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  class="close-sidebar-button"
                  data-dismiss="modal"
                  @click="updateMenuState"
                >
                  <div
                    class="icon-custom icon-close-circle-white"
                    style="width: 20px; height: 20px"
                  ></div>
                </a>
              </div>
              <!-- * profile box -->

              <ul
                class="
                  listview
                  flush
                  transparent
                  no-line
                  image-listview
                  navListCustomMargin
                "
              >
                <li
                  v-if="user.type == 1"
                  :class="{ disabledNavItem: !loggedIn }"
                >
                  <a
                    href="javascript:void(0)"
                    class="item"
                    @click="goTo('Shop')"
                  >
                    <div class="icon-box bg-active">
                      <div class="icon-custom icon-shop-outline-white"></div>
                    </div>
                    <div class="in">Üzletem</div>
                  </a>
                </li>
                <li>
                  <a
                    v-if="user.type != 0"
                    @click="$router.push({ name: 'MyServices' })"
                    href="javascript:void(0)"
                    class="item"
                  >
                    <div class="icon-box bg-active">
                      <div class="icon-custom icon-layers-outline-white"></div>
                    </div>
                    <div class="in">Szolgáltatásaim</div>
                  </a>
                </li>
                <li>
                  <a
                    v-if="user.type != 0"
                    @click="
                      $router.push({
                        name: 'ServiceProviderAppointments',
                      })
                    "
                    href="javascript:void(0)"
                    class="item"
                  >
                    <div class="icon-box bg-active">
                      <div class="icon-custom icon-alarm-outline-white"></div>
                    </div>
                    <div class="in">Időpontjaim</div>
                  </a>
                </li>
                <li :class="{ disabledNavItem: !loggedIn }">
                  <a
                    href="javascript:void(0)"
                    class="item"
                    @click="goTo('Messages')"
                  >
                    <div class="icon-box bg-active">
                      <div class="icon-custom icon-mail-outline-white"></div>
                    </div>
                    <div class="in">Üzenetek</div>
                  </a>
                </li>
                <li :class="{ disabledNavItem: !loggedIn }">
                  <a
                    href="javascript:void(0)"
                    @click="goTo('Notification')"
                    class="item"
                  >
                    <div class="icon-box bg-active">
                      <div class="icon-custom icon-warning-outline-white"></div>
                    </div>
                    <div class="in">
                      Értesítések
                      <span
                        v-if="notificationsCounter > 0"
                        class="badge badge-pill badge-active"
                        >{{ notificationsCounter }}</span
                      >
                    </div>
                  </a>
                </li>
                <!-- <li :class="{ disabledNavItem: !loggedIn }">
                <a
                  href="javascript:void(0)"
                  class="item"
                  @click="$router.push({ name: 'QR_Code' })"
                >
                  <div class="icon-box bg-active">
                    <div class="icon-custom icon-qr-code-outline-white"></div>
                  </div>
                  <div class="in">
                    QR kód
                  </div>
                </a>
              </li> -->
                <li :class="{ disabledNavItem: !loggedIn }">
                  <a
                    href="javascript:void(0)"
                    class="item"
                    @click="$router.push({ name: 'Contacts' })"
                  >
                    <div class="icon-box bg-active">
                      <div class="icon-custom icon-people-outline-white"></div>
                    </div>
                    <div class="in">
                      Kapcsolatok
                      <span
                        v-if="contactCounter > 0"
                        class="badge badge-pill badge-active"
                        >{{ contactCounter }}</span
                      >
                    </div>
                  </a>
                </li>
                <!--<li>-->
                <!--  <div class="item">-->
                <!--    <div class="icon-box bg-active">-->
                <!--      <div class="icon-custom icon-moon-outline-white"></div>-->
                <!--    </div>-->
                <!--    <div class="in">-->
                <!--      <div>Sötét téma</div>-->
                <!--      <div class="custom-control custom-switch">-->
                <!--        <input-->
                <!--          type="checkbox"-->
                <!--          class="custom-control-input dark-mode-switch"-->
                <!--          id="darkmodesidebar"-->
                <!--          @change="updateDarkModeFunction"-->
                <!--          v-model="darkMode"-->
                <!--        />-->
                <!--        <label-->
                <!--          class="custom-control-label"-->
                <!--          for="darkmodesidebar"-->
                <!--        ></label>-->
                <!--      </div>-->
                <!--    </div>-->
                <!--  </div>-->
                <!--</li>-->
              </ul>

              <!-- <div class="listview-title mt-2 mb-1">
              <span>Kedvencek</span>
            </div> -->

              <!--Kedvencek-->
              <!-- <ul class="listview image-listview flush transparent no-line">
              <li>
                <a href="javascript:void(0)" class="item">
                  <ion-icon name="bulb-outline"></ion-icon>
                  <div class="">
                    <div>Jelenleg nincsenek kedvencek</div>
                  </div>
                </a>
              </li>
            </ul> -->

              <!-- <div class="alert alert-primary mainGreen my-2 mx-3" role="alert">
              <ion-icon name="bulb-outline" class="mr-2"></ion-icon>
              Jelenleg a kedvencek üres!
            </div> -->
            </div>

            <!-- sidebar buttons -->
            <div v-if="loggedIn" class="sidebar-buttons">
              <a
                href="javascript:void(0)"
                @click="$router.push({ name: 'Settings' })"
                class="button"
              >
                <div class="col text-center">
                  <div class="icon-custom icon-settings"></div>
                  <div class="menuSubTitle">Beállítások</div>
                </div>
              </a>
              <a href="javascript:void(0);" class="button" @click="logout">
                <div class="col text-center">
                  <div class="icon-custom icon-log-out-outline"></div>
                  <div class="menuSubTitle">Kijelentkezés</div>
                </div>
              </a>
            </div>

            <div v-else class="sidebar-buttons">
              <a
                href="javascript:void(0)"
                @click="$router.push({ name: 'Login' })"
                class="button"
              >
                <div class="col text-center">
                  <div class="icon-custom icon-log-in-outline"></div>
                  <div class="menuSubTitle">Belépés</div>
                </div>
              </a>
              <a
                href="javascript:void(0);"
                class="button"
                @click="$router.push({ name: 'Registration' })"
              >
                <div class="col text-center">
                  <div class="icon-custom icon-key-outline"></div>
                  <div class="menuSubTitle">Regisztráció</div>
                </div>
              </a>
            </div>
            <!-- * sidebar buttons -->
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="menuShow"
      class="modal-backdrop fade show"
      @click="updateMenuState"
    ></div>
  </div>
</template>

<script>
// @ is an alias to /src
require("vue2-animate/dist/vue2-animate.min.css");
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Header",
  data: () => ({
    user: {},
    forceCloseNotificationPanel: false,
  }),
  methods: {
    ...mapActions(["updateMenuState", "closeMenu", "updateDarkModeState"]),
    updateDarkModeFunction() {
      this.$store.commit("updateDarkMode");

      const el = document.body;

      if (this.user.darkMode) {
        el.classList.add("dark-mode-active");
      } else {
        el.classList.remove("dark-mode-active");
      }
    },
    logout() {
      axios
        .post(this.$store.getters.getApiUrl + "logout", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          console.log("logout - resp", response.data);

          localStorage.token = null;
          localStorage.loginEmail = null;
          localStorage.loginPassword = null;
          localStorage.currentUser = null;
          localStorage.userId = null;

          this.$store.commit("updateLoginStatus", { loggedIn: false });

          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    goTo(routeName) {
      if (this.loggedIn) {
        this.$router.push({ name: routeName });
      }
    },
  },
  computed: {
    menuShow() {
      return this.$store.getters.getMenuState;
    },
    darkMode() {
      return this.$store.getters.getDarkModeState;
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    showNotification() {
      if (this.forceCloseNotificationPanel === false) {
        if (this.notificationsCounter > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    ...mapGetters(["notificationsCounter", "contactCounter", "userData"]),
  },
  components: {},
  props: {
    title: String,
  },
  mounted() {
    this.closeMenu();
    this.user = this.$store.getters.getUserData;
  },
};
</script>
