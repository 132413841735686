<template>
  <div>
    <Auth />

    <Header
      title="Szolgáltatásaim"
      back-route-name="Dashboard"
      custom-class="bg-active text-light"
    />

    <div class="fab-button animate bottom-right pb-5 mb-2">
      <a
        href="javascript:void(0)"
        @click="$router.push({ name: 'MyServiceNew' })"
        class="fab"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <ion-icon
          name="add-outline"
          role="img"
          class="md hydrated"
          aria-label="add outline"
        ></ion-icon>
      </a>
    </div>

    <div v-if="services && services.length > 0" class="section inset">
      <div
        class="wide-block wideBlockMainPurple pt-2 pb-2 mt-1"
        v-for="(service, index) in services"
        :key="index"
        href="javascript:void(0)"
        @click="
          $router.push({ name: 'MyServiceEdit', params: { id: service.id } })
        "
      >
        <div class="content-header mb-05">
          <br />
        </div>
        {{ service.title }}

        <div
          class="d-inline-block text-primary text-right float-right"
          style="font-size: 2rem; margin-top: -1.5rem"
        >
          <div class="icon-custom icon-right-arrow-white"></div>
        </div>

        <div class="content-footer mt-05 text-right">
          Időtartam: {{ service.duration }} perc <br />
          Ár: {{ service.price }} HUF
        </div>
      </div>
    </div>

    <div v-else class="section full">
      <ErrorPanel
        class-label="alert alert-danger mainGreen"
        title="Hoppá!"
        message="Nincsenek elérhető szolgáltatások!"
        message2="Kérlek adj hozzá egyet!"
      />
    </div>

    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";
import ErrorPanel from "@/partials/ErrorPanel";

import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "MyServices",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
    ErrorPanel,
  },
  data: () => ({
    errors: {},
    services: {},
    user: {},
  }),
  methods: {
    getService() {
      axios
        .post(this.$store.getters.getApiUrl + "service/sp/show", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.services = response.data.services;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.user = this.$store.getters.getUserData;
    this.getService();
  },
};
</script>
