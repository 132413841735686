<template>
  <div>
    <router-view id="appCapsule" />
  </div>
  <!-- <div v-else>
    <router-view />
  </div> -->
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["headerState"]),
  },
  mounted() {
    console.log("headerState: ", this.headerState);
  },
};
</script>

<style></style>
