<template>
  <div>
    <RegistrationHeaderServiceProvider />

    <div class="section mb-2 mt-2 full">
      <div class="section-title pt-2 text-light">Szálgáltatások</div>
      <div class="wide-block pt-2 pb-2 spCategoryService">
        <form id="form1" action="#" enctype="multipart/form-data" method="post">
          <div class="section inset mt-4" v-show="services.length < 1">
            <div class="alert alert-magenta mb-1" role="alert">
              A folytatáshoz, kérlek adj hozzá egy szolgáltatást!
            </div>
          </div>

          <div class="section inset mt-4 mb-4">
            <div
              class="wide-block pt-2 pb-2 mb-2"
              v-for="(service, index) in services"
              :key="'service_' + index"
            >
              <div class="content-header mb-05">
                Szolgáltatás # <span v-html="index"></span>
                <span
                  @click="removeService(index)"
                  class="text-danger float-right"
                  >Eltávolít</span
                >
              </div>

              <div class="form-group boxed">
                <div class="input-wrapper">
                  <input
                    type="text"
                    class="form-control"
                    v-model="service.name"
                    placeholder="Megnevezés"
                  />
                </div>
              </div>

              <div class="form-group boxed">
                <div class="input-wrapper">
                  <div class="row">
                    <div class="col-9">
                      <input
                        type="number"
                        class="form-control"
                        v-model="service.price"
                        placeholder="Ár"
                      />
                    </div>
                    <div
                      class="col-2 align-self-center"
                      style="font-size: 1.15rem; color: #6c757d"
                    >
                      Forint
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group boxed">
                <div class="input-wrapper">
                  <div class="row">
                    <div class="col-9">
                      <input
                        type="number"
                        class="form-control"
                        v-model="service.duration"
                        placeholder="Időtartam (percben)"
                        @change="checkDuration(service)"
                      />
                      <small
                        v-show="service.durationError"
                        class="text-danger ml-2"
                        >Az időtartamot percben kell megadni</small
                      >
                    </div>
                    <div
                      class="col-2 align-self-center"
                      style="font-size: 1.15rem; color: #6c757d"
                    >
                      perc
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group boxed">
                <div class="input-wrapper">
                  <div class="row">
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="service.day_limit"
                        placeholder="Napi limit"
                      />
                    </div>
                    <div
                      class="col-5 align-self-center"
                      style="font-size: 0.85rem; color: #6c757d"
                    >
                      alkalom / nap
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group boxed">
                <textarea
                  type="text"
                  rows="5"
                  class="form-control"
                  id="description"
                  name="description"
                  placeholder="Rövid leírás"
                  v-model="service.description"
                  :class="{ 'is-invalid': errors.description }"
                />
              </div>
            </div>
          </div>

          <div class="form-button-group">
            <div>
              <div
                @click="addNewService"
                class="btn btn-primary btn-lg btn-block mt-1"
              >
                Új szolgáltatás
              </div>
              <div
                @click="saveServices"
                class="btn btn-magenta btn-lg btn-block mb-1"
              >
                Befejezés
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import RegistrationHeaderServiceProvider from "../../partials/registration/RegistrationHeaderServiceProvider";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderStep4",
  data: () => ({
    user: "",
    selectedCategories: [],
    categories: "",
    services: [],
    errors: {},
    registrationInProgress: false,
  }),
  components: {
    RegistrationHeaderServiceProvider,
  },
  methods: {
    addNewService() {
      this.services.push({
        name: "",
        price: "",
        duration: "",
        durationError: false,
        day_limit: "",
        description: "",
      });
    },
    removeService(index) {
      // let i = this.services.map(service => service.name).indexOf(index);
      this.services.splice(index, 1);
    },
    saveServices() {
      if (this.services.length < 1) {
        //Display - error
        this.$toasted.show("Legalább 1 szolgáltatás megadása kötelező!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
          icon: "error_outline",
        });
      } else {
        //Store tmp services data before push all data to API
        this.$store.commit("updateRegistrationServices", {
          services: this.services,
        });

        //Check registration status
        if (!this.registrationInProgress) {
          //Set registration status to inProgress
          this.registrationInProgress = true;

          //Send Services data to API & log in to dashboard
          axios
            .post(
              this.$store.getters.getApiUrl +
                "registration/service_provider_step4",
              {
                email: this.user.email,
                services: this.services,
                categories: this.selectedCategories,
              }
            )
            .then((response) => {
              if (response.status === 200) {
                this.$router.push({ name: "Dashboard" });
              }
            })
            .catch((error) => {
              this.errors = error.response.data.errors;
            });
        }
      }
    },
    checkDuration(service) {
      //Check the length
      if (service.duration.length > 0) {
        //Check the type of input
        let durationCheck = Number(service.duration);
        if (!isNaN(durationCheck)) {
          service.durationError = false;
        } else {
          console.log(durationCheck);
          service.durationError = true;
        }
      } else {
        service.durationError = true;
      }
    },
  },
  mounted() {
    this.$store.state.registration.step = 4;
    this.user = this.$store.getters.getUserData;
    this.categories = this.$store.getters.getRegistrationCategories;
    this.selectedCategories =
      this.$store.getters.getRegistrationChoosedCategories;
    this.services = this.$store.getters.getRegistrationServices;

    //Add bg class to body
    document.body.classList.add("registrationBgServiceProvider");
  },
  destroyed() {
    //Remove bg class to body
    document.body.classList.remove("registrationBgServiceProvider");
  },
};
</script>
