<template>
  <div class="login-form mainDashboard disableYPadding">
    <div class="section mt-5">
      <img
        :src="require('@/assets/img/logo/fixeet-full-white-logo.png')"
        alt="fixeet_logo"
        class="loginLogo"
        @click="$router.push({ name: 'Dashboard' })"
      />
      <h2 class="mt-2 text-white">Jelszó visszaállítás</h2>
    </div>
    <div class="section mt-5">
      <!-- <h1>Bejelentkezés</h1>
      <h4>Add meg adataidat a belépéshez</h4> -->
      <!-- <p v-show="!errors.success" class="text-danger">{{ errors.message }}</p> -->
    </div>
    <div v-show="showForm" class="section mt-3 mb-5">
      <form id="form1">
        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="email"
              class="form-control"
              id="email1"
              name="email"
              placeholder="Email cím"
              v-model="form.email"
              :class="{ 'is-invalid': errors.email }"
            />
            <!-- <div class="invalid-feedback" v-if="errors.errors.email">
              {{ errors.errors.email[0] }}
            </div> -->
          </div>
        </div>

        <div class="form-links mt-2">
          <div>
            <button
              @click="$router.push({ name: 'Login' })"
              class="btn btn-magenta btn-sm"
            >
              Bejelentkezés
            </button>
          </div>
          <!-- <div>
            <a href="#" class="text-white">Jelszó visszaállítás</a>
          </div> -->
        </div>

        <div class="form-button-group">
          <div class="btn btn-blue btn-block btn-lg" @click="goRecover">
            Visszaállítás
          </div>
        </div>
      </form>
    </div>
    <div v-show="!showForm" class="form-button-group mb-3">
      <div class="btn btn-blue btn-block btn-lg" @click="showForm = true">
        Visszaállítás
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Home",
  components: {},
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    deviceToken: "",
    deviceManufacturer: "",
    errors: {},
    showForm: true,
  }),
  methods: {
    goRecover() {
      this.errors = "";

      axios
        .post(this.$store.getters.getApiUrl + "password_recovery", {
          email: this.form.email,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success) {
              //Show message
              this.$toasted.show(
                "Sikeres visszaállítás!<br> Ellenőrizze postafiókját!",
                {
                  position: "bottom-center",
                  duration: 2500,
                  fullWidth: true,
                  fitToScreen: false,
                  type: "success",
                  // icon: "checkmark-outline"
                }
              );
              setTimeout(() => {
                this.$router.push({ name: "Home" });
              }, 1500);
            }
          } else {
            //Show message
            this.$toasted.show("Email cím nem található!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: false,
              type: "error",
              // icon: "checkmark-outline"
            });
          }
        })
        .catch((error) => {
          console.log("hiba: ", error.response.data);
          this.errors = error.response.data.errors;

          if (this.errors.email && this.errors.email.length > 0) {
            // console.log("anyad");
            //Show message
            this.$toasted.show("Email cím megadása kötelező!", {
              position: "bottom-center",
              duration: 3500,
              fullWidth: true,
              fitToScreen: false,
              type: "error",
              // icon: "checkmark-outline"
            });
          }
        });
    },
  },
  mounted() {
    //Add bg class to body
    document.body.classList.add("lostPasswordBg");
  },
  destroyed() {
    document.body.classList.remove("lostPasswordBg");
  },
};
</script>

<style>
</style>
