<template>
  <div>
    <Auth />

    <Header title="Üzletem" back-route-name="Dashboard" />

    <div class="section mt-2">
      <form id="form1" action="#" enctype="multipart/form-data" method="post">
        <div class="picture-container">
          <div class="picture mb-2">
            <img
              id="wizardPicturePreview"
              class="picture-src"
              :src="shopData.profileImage"
              title="profil kép"
              onclick="document.getElementById('wizard-picture').click()"
            />
            <input
              id="wizard-picture"
              class=""
              name="profileImage"
              type="file"
              v-on:change="refreshProfileImage"
            />
            <div class="fab-button animate profilePhotoEdit">
              <div id="profilePhotoEditBtn" class="fab profilePhotoEditButton">
                <ion-icon
                  aria-label="add outline"
                  class=""
                  name="camera-outline"
                  role="img"
                ></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              id="name1"
              name="name"
              placeholder="Név"
              v-model="shopData.name"
              :class="{ 'is-invalid': errors.name }"
            />
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="email"
              class="form-control"
              id="email1"
              name="email"
              placeholder="Email cím"
              v-model="shopData.email"
              :class="{ 'is-invalid': errors.email }"
            />
            <div class="invalid-feedback" v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              id="phone1"
              name="phone"
              placeholder="Telefonszám"
              v-model="shopData.phone"
              :class="{ 'is-invalid': errors.phone }"
            />
            <div class="invalid-feedback" v-if="errors.phone">
              {{ errors.phone[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              name="zip"
              placeholder="Irányítószám"
              v-model="shopData.zip"
              :class="{ 'is-invalid': errors.zip }"
            />
            <div class="invalid-feedback" v-if="errors.zip">
              {{ errors.zip[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              name="city"
              placeholder="Város"
              v-model="shopData.city"
              :class="{ 'is-invalid': errors.city }"
            />
            <div class="invalid-feedback" v-if="errors.city">
              {{ errors.city[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <input
              type="text"
              class="form-control"
              name="address"
              placeholder="Cím"
              v-model="shopData.address"
              :class="{ 'is-invalid': errors.address }"
            />
            <div class="invalid-feedback" v-if="errors.address">
              {{ errors.address[0] }}
            </div>
          </div>
        </div>

        <div class="form-group boxed">
          <div class="input-wrapper">
            <textarea
              type="text"
              class="form-control"
              rows="5"
              name="introduction"
              placeholder="Bemutatkozás"
              v-model="shopData.introduction"
              :class="{ 'is-invalid': errors.introduction }"
            >
            </textarea>
            <div class="invalid-feedback" v-if="errors.introduction">
              {{ errors.introduction[0] }}
            </div>
          </div>
        </div>

        <div
          @click="$router.push({ name: 'ShopUsers' })"
          class="btn btn-magenta btn-lg btn-block my-2"
        >
          Felhasználók kezelése
        </div>
        <div
          @click="saveNewData"
          class="btn btn-success btn-lg btn-block mt-1 mb-5"
        >
          Mentés
        </div>
      </form>
    </div>
    <Nav />
    <NavFooter />
  </div>
</template>

<script>
import Header from "../partials/Header";
import Auth from "../components/Auth";
import Nav from "../partials/Nav";
import NavFooter from "../partials/NavFooter";

import axios from "axios";
import { mapGetters } from "vuex";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "Shop",
  components: {
    Auth,
    Header,
    Nav,
    NavFooter,
  },
  data: () => ({
    user: {},
    shop: {},
    errors: {},
    deletePassword: "",
    uploadedFile: "",
    showReminderModal: false,
    showDeletionModal: false,
  }),
  methods: {
    saveNewData() {
      this.errors = "";

      let data = new FormData();
      data.append("email", this.shopData.email);
      data.append("name", this.shopData.name);
      data.append("zip", this.shopData.zip);
      data.append("city", this.shopData.city);
      data.append("address", this.shopData.address);
      data.append("phone", this.shopData.phone);
      data.append("uploadedFile", this.uploadedFile);
      data.append("token", this.$store.getters.getToken);
      data.append("introduction", this.shopData.introduction);

      axios
        .post(this.$store.getters.getApiUrl + "shop/update", data)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("loginSuccess", {
              user: response.data.user,
              shop: response.data.shop,
            });

            //Toast success message
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    refreshProfileImage(e) {
      const file = e.target.files[0];
      this.shopData.profileImage = URL.createObjectURL(file);
      this.uploadedFile = file;
    },
    saveReminderData() {
      this.errors = "";

      axios
        .post(this.$store.getters.getApiUrl + "user/updateReminder", {
          token: this.$store.getters.getToken,
          reminderTime: this.user.reminderTime,
          reminderUnit: this.user.reminderUnit,
        })
        .then((response) => {
          if (response.status === 200) {
            //Init settings
            this.showReminderModal = false;
            this.$store.commit("loginSuccess", {
              user: response.data.user,
            });

            //Toast success message
            this.$toasted.show("Sikeres mentés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    deleteAccount() {
      this.errors = "";

      axios
        .post(this.$store.getters.getApiUrl + "user/deleteAccount", {
          token: this.$store.getters.getToken,
          deletePassword: this.deletePassword,
        })
        .then((response) => {
          if (response.status === 200) {
            //Init settings
            localStorage.token = null;
            localStorage.loginEmail = null;
            localStorage.loginPassword = null;
            localStorage.currentUser = null;
            localStorage.userId = null;

            this.$store.commit("updateLoginStatus", { loggedIn: false });

            this.$router.push({ name: "Home" });

            //Toast success message
            this.$toasted.show("Sikeres fiók törlés!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          if (error.response.status === 500) {
            //Toast error message
            this.$toasted.show("Hibás jelszó!", {
              position: "bottom-center",
              duration: 2500,
              fullWidth: true,
              fitToScreen: true,
              type: "error",
            });
          }
        });
    },
  },
  computed: {
    ...mapGetters(["shopData"]),
  },
  mounted() {
    this.$data.user = this.$store.getters.getUserData;

    //Add bg class to body
    document.body.classList.add("myShopBg");
  },
  destroyed() {
    document.body.classList.remove("myShopBg");
  },
};
</script>
