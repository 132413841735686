<template>
  <div>
    <div class="appBottomMenu">
      <a
        href="javascript:void(0);"
        @click="$router.push({ name: 'Dashboard' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-home"></div>
          <strong>Főoldal</strong>
        </div>
      </a>
      <a
        v-if="loggedIn && user.type === 0"
        href="javascript:void(0);"
        @click="$router.push({ name: 'Search' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-search"></div>
          <strong>Kereső</strong>
        </div>
      </a>
      <a
        v-if="!loggedIn"
        href="javascript:void(0);"
        @click="$router.push({ name: 'Search' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-search"></div>
          <strong>Kereső</strong>
        </div>
      </a>
      <a
        v-if="loggedIn && user.type != 0"
        href="javascript:void(0);"
        @click="$router.push({ name: 'ServiceProviderCalendar' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-calendar"></div>
          <span v-if="appointmentsCounter" class="badge badge-active">
            {{ appointmentsCounter }}
          </span>
          <strong>Időpontjaim</strong>
        </div>
      </a>

      <a
        v-if="loggedIn && user.type == 0"
        href="javascript:void(0);"
        @click="$router.push({ name: 'Calendar' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-calendar"></div>
          <span v-if="appointmentsCounter" class="badge badge-active">
            {{ appointmentsCounter }}
          </span>
          <strong>Foglalásaim</strong>
        </div>
      </a>

      <a
        href="javascript:void(0);"
        v-if="loggedIn"
        @click="$router.push({ name: 'Messages' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-chatbubbles"></div>
          <span v-if="conversationsCounter" class="badge badge-active">
            {{ conversationsCounter }}
          </span>
          <strong>Üzenetek</strong>
        </div>
      </a>

      <a
        href="javascript:void(0);"
        @click="$router.push({ name: 'Faq' })"
        class="item"
      >
        <div class="col">
          <div class="icon-custom icon-information-circle"></div>
          <strong>GYIK</strong>
        </div>
      </a>

      <!--<a-->
      <!--  @click="updateMenuState"-->
      <!--  href="javascript:void(0);"-->
      <!--  class="item"-->
      <!--  data-toggle="modal"-->
      <!--  data-target="#sidebarPanel"-->
      <!--&gt;-->
      <!--  <div class="col">-->
      <!--    <ion-icon-->
      <!--      name="menu-outline"-->
      <!--      role="img"-->
      <!--      class="md hydrated"-->
      <!--      aria-label="menu outline"-->
      <!--    ></ion-icon>-->
      <!--    <strong>Menü</strong>-->
      <!--  </div>-->
      <!--</a>-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

require("vue2-animate/dist/vue2-animate.min.css");
import { mapActions } from "vuex";

export default {
  name: "Header",
  data: () => ({
    user: {},
    appointmentsCounter: null,
    conversationsCounter: null,
    notificationCounter: null,
    contactCounter: null,
  }),
  methods: {
    ...mapActions(["updateMenuState"]),
    getAppointmentNotificationsCounter() {
      axios
        .post(this.$store.getters.getApiUrl + "notification/nav/counters", {
          token: this.$store.getters.getToken,
        })
        .then((response) => {
          if (response.status === 200) {
            this.appointmentsCounter = response.data.appointmentsCounter;
            this.conversationsCounter = response.data.conversationsCounter;
            this.notificationsCounter = response.data.notificationsCounter;
            this.contactCounter = response.data.contactCounter;

            //Store counters
            this.$store.commit("updateCounters", {
              notifications: response.data.notificationsCounter,
              conversations: response.data.conversationsCounter,
              appointments: response.data.appointmentsCounter,
              contacts: response.data.contactCounter,
            });

            //Fire notification
            //Create a notification in OS level
            //TODO - only experimental use now, but it works
            // this.createNotificationWithNotificationAPI();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    createNotificationWithNotificationAPI() {
      const promise = Notification.requestPermission();
      promise.then(function (result) {
        console.log(result);

        if (result === "granted") {
          console.log("Notification - fired");
          var img = require("@/assets/img/favicon.png");
          var text = "Értesítés";
          var n = new Notification("FiXeeT", { body: text, icon: img });
          n.vibrate;
        } else {
          console.log("disabled");
        }
      });
    },
  },
  computed: {
    menuShow() {
      return this.$store.getters.getMenuState;
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
  },
  components: {},
  props: {
    title: String,
  },
  mounted() {
    this.$data.user = this.$store.getters.getUserData;

    //If we logged in, we count notifications
    if (this.loggedIn) {
      this.getAppointmentNotificationsCounter();
    }
  },
};
</script>
