<template>
  <div class="section inset">
    <div
      class="wide-block bg-white bg-tarnsparent-95 pt-2 pb-2 mt-1"
      v-for="(sp, index) in shops"
      :key="index"
      href="javascript:void(0)"
      @click="
        $router.push({ name: 'SearchShopServices', params: { id: sp.id } })
      "
    >
      <div class="content-header mb-05 text-black-100">
        {{ sp.zip }} {{ sp.city }}, {{ sp.address }}
      </div>
      <div class="row">
        <div class="col-3">
          <div class="avatar">
            <div
              v-if="sp.profile_image"
              :style="{
                backgroundImage: 'url(' + sp.profile_image + ')',
              }"
              class="profileImage"
            ></div>
            <div
              v-else
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/profile.jpg') + ')',
              }"
              class="profileImage"
            ></div>
          </div>
        </div>
        <div class="col-9">
          {{ sp.name }}

          <div
            class="d-inline-block text-primary text-right float-right"
            style="font-size: 2rem; margin-top: -1.5rem"
          >
            <div class="icon-custom icon-chevron-forward-circle-outline"></div>
          </div>

          <div class="content-footer mt-05 text-right">
            <div class="row">
              <div class="col-6 text-left text-black-100">
                Tel: {{ sp.phone }} <br />
                Email: {{ sp.email }}
              </div>
              <div class="col-6 text-right">
                <!--Időtartam: {{ service.duration }} perc <br />-->
                <!--Ár: {{ service.price }} HUF-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Box",
  data: () => ({}),
  components: {},
  computed: {},
  props: {
    shops: Array,
  },
  methods: {},
  mounted() {},
};
</script>
