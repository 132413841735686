<template>
  <div>
    <RegistrationHeaderServiceProvider />

    <div class="section mb-2 mt-2 full">
      <div class="section-title pt-2 text-light">Kategóriák</div>
      <div class="wide-block pt-2 pb-2 spCategoryList">
        <form id="form1" action="#" enctype="multipart/form-data" method="post">
          <div class="input-list">
            <div
              class="custom-control custom-checkbox"
              v-for="(category, index) in categories"
              :key="index"
            >
              <input
                type="checkbox"
                :id="'category_' + category.id"
                :value="category.id"
                class="custom-control-input"
                v-model="selectedCategories"
              />
              <label
                :for="'category_' + category.id"
                class="custom-control-label"
                >{{ category.title }}</label
              >
            </div>
          </div>

          <div class="form-button-group">
            <div
              class="btn btn-magenta btn-lg btn-block"
              @click="saveCategories"
            >
              Tovább
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "@/assets/js/lib/jquery-3.4.1.min";
import axios from "axios";
import RegistrationHeaderServiceProvider from "../../partials/registration/RegistrationHeaderServiceProvider";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ServiceProviderStep3",
  data: () => ({
    user: "",
    selectedCategories: [],
    categories: "",
    errors: {},
  }),
  components: {
    RegistrationHeaderServiceProvider,
  },
  methods: {
    saveCategories() {
      if (!this.selectedCategories) {
        //Display - error
        this.$toasted.show("Legalább 1 kategória választása kötelező!", {
          position: "bottom-center",
          duration: 3500,
          fullWidth: true,
          fitToScreen: true,
          type: "error",
          icon: "error_outline",
        });
      } else {
        this.$store.commit("updateRegistrationChoosedCategories", {
          categories: this.selectedCategories,
        });

        this.$router.push({ name: "ServiceProviderStep4" });
      }
    },
  },
  mounted() {
    this.$store.state.registration.step = 3;
    this.user = this.$store.getters.getUserData;
    this.categories = this.$store.getters.getRegistrationCategories;
    this.selectedCategories =
      this.$store.getters.getRegistrationChoosedCategories;

    //Add bg class to body
    document.body.classList.add("registrationBgServiceProvider");
  },
  destroyed() {
    //Remove bg class to body
    document.body.classList.remove("registrationBgServiceProvider");
  },
};
</script>
